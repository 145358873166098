import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import { withTranslation, WithTranslation } from 'react-i18next';
import FontAwesomeIcon from '../FontAwesome';

interface EditTicketInfobarProps extends WithTranslation {
    id: number;
    canSave: boolean;
    onSave?: Function;
    onCancel?: Function;
}

class EditTicketInfobar extends Component<EditTicketInfobarProps, any> {
    constructor(props: any) {
        super(props);
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    onSave(e: any) {
        e.preventDefault();
        if (this.props.onSave) {
            this.props.onSave();
        }
    }

    onCancel(e: any) {
        e.preventDefault();
        if (this.props.onCancel) {
            this.props.onCancel();
        }
    }

    render() {
        const { t } = this.props;
        return (
            <div className="infobar">
                <div className="container">
                    <div className="infos">
                        <span className="icon"><FontAwesomeIcon icon={'list'} size={'fw'} /></span>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to={'/tickets'}>{t('EditTicketInfobar.allRequests')}</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">{t('EditTicketInfobar.requestId', { id: this.props.id })}</li>
                        </ol>
                    </div>
                    <div className="actions">
                        <button className="btn btn-outline-light" onClick={this.onCancel}><FontAwesomeIcon icon={'close'} size={'fw'} /><span className="d-none d-sm-inline-block"> {t('EditTicketInfobar.cancel')}</span></button>
                        <button className="btn btn-primary" disabled={!this.props.canSave} onClick={this.onSave}><FontAwesomeIcon icon={'check'} size={'fw'} /><span className="d-none d-sm-inline-block"> {t('EditTicketInfobar.save')}</span></button>
                    </div>
                    <Nav as="ul">
                        <Nav.Item as="li">
                            <Nav.Link eventKey="edit"><FontAwesomeIcon icon={'pencil'} size={'fw'} /> {t('EditTicketInfobar.editTabTitle')}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                            <Nav.Link eventKey="history"><FontAwesomeIcon icon={'clock-o'} size={'fw'} /> {t('EditTicketInfobar.historyTabTitle')}</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </div>
            </div>
        );
    }
}

export default withTranslation()(EditTicketInfobar);