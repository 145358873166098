import React, { Component } from 'react';

export default class AttachmentList extends Component {
    render() {
        return (<div className="k-widget k-upload k-header k-custom-upload">
            <ul className="k-upload-files k-reset">
                {this.props.children}
            </ul>
        </div>);
        ;
    }
}