import React, { Component } from 'react';
import { Spinner } from 'react-bootstrap';

type LoaderProps = {
    variant?: 'primary'
        | 'secondary'
        | 'success'
        | 'danger'
        | 'warning'
        | 'info'
        | 'light'
        | 'dark',
    animation?: 'border' | 'grow'
}

export default class Loader extends Component<LoaderProps, any> {
    render() {
        const variant = this.props.variant || 'primary';
        const animation = this.props.animation || 'border';
        return (
            <div className="container">
                <div className="d-flex justify-content-center align-items-center">
                    <Spinner animation={animation} variant={variant} />
                </div>
            </div>);
    }
}