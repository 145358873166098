import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import EditTicketForm from './EditTicketForm';
import NewTicketForm from './NewTicketForm';
import { LocalizationProvider } from '@progress/kendo-react-intl';
import { withTranslation, WithTranslation } from 'react-i18next';

type TicketFormState = {
    id: number
    initialized: boolean
}

interface MatchParams {
    ticketId?: string;
}

interface MatchProps extends RouteComponentProps<MatchParams> {
}


class TicketForm extends Component<MatchProps & WithTranslation, TicketFormState> {

    constructor(props: any) {
        super(props);
        const { match: { params } } = this.props;
        this.state = {
            initialized: true,
            id: params.ticketId ? parseInt(params.ticketId) : 0
        };
    }

    componentWillMount() {
        document.body.className = "page-ticket infobar-fixed";
    }

    render() {
        const { i18n } = this.props;

        if (this.state.id > 0) {
            return (<LocalizationProvider language={i18n.language}><EditTicketForm id={this.state.id} /></LocalizationProvider>);
        }
        else {
            return (<LocalizationProvider language={i18n.language}><NewTicketForm /></LocalizationProvider>);
        }
    }
}

export default withTranslation()(TicketForm);
