export class SearchFilterSettings {
    ticketId: number | undefined = undefined;
    
    text : string = '';
    
    processedBy: number | null = null;
    
    requestedBy: number | null = null;

    from : Date | null = null;
    
    until: Date | null = null;

    status : Array<any> = [];

    priorities : Array<any> = [];

    themes : Array<any> = [];

    skip : number = 0;

    static pageSize : number = 100;

    reset() {
        this.text = '';
        this.ticketId = undefined;
        this.from = null;
        this.until = null;
        this.processedBy = null;
        this.requestedBy = null;
        this.status = [];
        this.priorities = [];
        this.themes = [];
    }
}

const searchFilterSettings = new SearchFilterSettings();
export default searchFilterSettings;