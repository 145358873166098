import React, { Component } from 'react';
import { TicketStatusDefinitions } from '../Enums';
type TicketStatusProps = {
    status: number
}

export default class TicketStatus extends Component<TicketStatusProps, any> {
    render() {
        const statusDefinition = TicketStatusDefinitions.find(item => item.id === this.props.status);
        if (statusDefinition) {
            return (<span className={'state-label ' + statusDefinition.class}><span className="bullet" title={statusDefinition.label}></span><span className="text">{statusDefinition.label}</span></span>);
        }
        else {
            return (<span>#{this.props.status}#</span>);
        }
    }
}