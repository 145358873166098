import React, { Component } from 'react';

export type MetaFieldProps = {
    label: string,
    value: any | null
}

export class MetaContainer extends Component {
    render() {
        return (
            <div className="metas">
                {this.props.children}
            </div>
        )
    }
}

export class MetaField extends Component<MetaFieldProps, any> {
    render() {
        const val = this.props.value ? this.props.value : "--";
        return (
            <div className="meta">
                <span className="label">{this.props.label}</span>
                <span className="value">{val}</span>
            </div>);
    };
}
