import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import i18n from "i18next";

type ConfirmDialogProps = {
    title: string,
    centered?: boolean,
    onConfirm?: Function,
    onCancel?: Function,
    confirmButtonLabel?: string,
    cancelButtonLabel?: string,
    showDialog?: boolean
};

export default class ConfirmDialog extends Component<ConfirmDialogProps> {
    constructor(props: any) {
        super(props);
        this.confirm = this.confirm.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    confirm(e: any) {
        if (this.props.onConfirm) {
            this.props.onConfirm();
        }
    }

    cancel(e: any) {
        if (this.props.onCancel) {
            this.props.onCancel();
        }
    }

    render() {
        const defaultConfirmLabel = i18n.t('buttons.yes');
        const defaultCancelLabel = i18n.t('buttons.no');
        return (
            <Modal.Dialog size="lg"
                hidden={!this.props.showDialog} 
                centered={this.props.centered}
                aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.props.children}
                </Modal.Body>
                <Modal.Footer>
                    <button className={'btn btn-primary'} onClick={this.confirm}>{this.props.confirmButtonLabel || defaultConfirmLabel}</button>
                    <button className={'btn'} onClick={this.cancel}>{this.props.cancelButtonLabel || defaultCancelLabel}</button>
                </Modal.Footer>
            </Modal.Dialog>
        );
    }
}