import { ServiceUri } from './ServicesUri';
class JsonService {
    _baseUri: string;
    constructor(baseUri?: string) {
        this._baseUri = baseUri || '';
    }

    get(uri: string, headers?: any): Promise<Response> {
        return this.execute(uri, 'GET', headers);
    }

    post(uri: string, headers?: any, body?: any): Promise<Response> {
        headers = JsonService.getHeaders(headers);
        return this.execute(uri, 'POST', headers, body);
    }

    upload(uri: string, file: any, headers?: any) : Promise<Response> {
        if (!headers) {
            headers = {  };
        }
        headers['Access-Control-Allow-Origin'] = '*';
        var formData = new FormData();
        formData.append('files', file);
        return fetch(this._baseUri + uri, { method: 'POST', headers: headers, body: formData });
    }

    put(uri: string, headers?: any, body?: any): Promise<Response> {
        headers = JsonService.getHeaders(headers);
        return this.execute(uri, 'PUT', headers, body);
    }

    delete(uri: string, headers?: any): Promise<Response> {
        return this.execute(uri, 'DELETE', headers);
    }

    private execute(uri: string, method: string, headers?: any, body?: any): Promise<Response> {
        return fetch(this._baseUri + uri, { method: method, headers: headers, body: body ? JSON.stringify(body) : null });
    }

    private static getHeaders(headers?: any): any {
        if (!headers) {
            headers = { 'Content-Type': 'application/json' };
        }
        else {
            headers['Content-Type'] = 'application/json';
        }
        headers['Access-Control-Allow-Origin'] = '*';
        return headers;
    }
}

const jsonService = new JsonService(ServiceUri);
export default jsonService;