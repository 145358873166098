import React, { Component } from 'react';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import agentService, { AgentService } from './services/AgentService';
type Props = {
    userId: number | null,
    required: boolean,
    disabled: boolean,
    onChange?: Function
}

type State = {
    value: any,
    users : Array<any>
}

export default class AgentList extends Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            value: {},
            users: []
        }
        this.filterChange = this.filterChange.bind(this);
    }

    defaultItem = { };


    componentDidUpdate(prevProps: Props) {
        if(prevProps.userId !== this.props.userId)
        {
            let agent = null;
            if(this.props.userId)
            {
                const list = this.state.users;
                agent = list.find((u: any) => u.id === this.props.userId);
            }            
            this.setState({value: agent});
        }
        else if (prevProps.disabled !== this.props.disabled || prevProps.required !== this.props.required) {
            this.setState({});
        }
    }

    async componentWillMount() {
        let list = await agentService.getAgents();
        list = list.sort(AgentService.compare).map((u : any) => {return { id: u.id, displayName: AgentService.formatUserName(u)};});
        let agent = null;
        if(this.props.userId)
        {
            agent = list.find((u: any) => u.id === this.props.userId);
        }
        this.setState({users: list, value: agent});
    }

    handleChange(value : any) {
        this.setState({value : value});
        if(this.props.onChange) {
            this.props.onChange(value);
        }
    }

    async filterChange(event : any) {
        let list = await agentService.getAgents();
        if(event.filter.value && event.filter.value.length > 0) {
            const searchValue = event.filter.value.toLowerCase();
            list = list.filter((u : any) => (u.firstName && u.firstName.toLowerCase().includes(searchValue)) || (u.lastName && u.lastName.toLowerCase().includes(searchValue)));
        }
        let filtered = list.sort(AgentService.compare).map((u : any) => {return { id: u.id, displayName: AgentService.formatUserName(u)};});
        this.setState({users: filtered});  
    }


    render() {
        return (<ComboBox className={'form-control'} data={this.state.users} textField={'displayName'} dataItemKey={'id'}
            filterable={true}
            required={this.props.required}
            disabled={this.props.disabled}
            onFilterChange={this.filterChange}
            value={this.state.value}
            onChange={(e: any) => {
                this.handleChange(e.target.value);
            }} />);
    }
}
