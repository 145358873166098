import React, { Component } from 'react';
import Comment from './Comment';

type CommentListProps = {
    items: Array<any> | null
}

export default class CommentList extends Component<CommentListProps, any> {
    render() {
        if (!this.props.items) return null;

        return (
            <div className="comments">
                {
                    (this.props.items || []).map((comment, index) => <Comment key={index} author={comment.author} message={comment.message} timestamp={comment.creationTime} />)
                }
            </div>
        );
    }
}