import React, {Component} from 'react';
import FranchiseNewTicketForm from './franchise/FranchiseNewTicketForm';
import AgentNewTicketForm from './agent/AgentNewTicketForm';
import NotFound from '../NotFound';

import authenticationService from '../services/AuthenticationService';

export default class NewTicketForm extends Component {
    
    render() {
        const me = authenticationService.me();
        if(me && me.role === 'agent') {
            return (<AgentNewTicketForm />);
        }
        else if(me && me.role === 'franchise') {
            return (<FranchiseNewTicketForm />);
        }
        else {
            return (<NotFound component={'NewTicketForm'}/>);        
        }
    }
}