import React, { Component, Fragment } from 'react';
import Topbar from '../../Topbar';
import ListInfobar from '../ListInfobar';
import { StatCard, DoubleStatCard } from '../StatCard';
import {registerForIntl} from '@progress/kendo-react-intl';
import AgentTicketGrid from './AgentTicketGrid';
import AgentTicketSearchFilter from './AgentTicketSearchFilter';
import ticketService from '../../services/TicketService';
import searchFilterSettings from '../../services/SearchFilterSettings';
import { withTranslation, Trans } from 'react-i18next';
import FontAwesomeIcon from '../../FontAwesome';

type AgentTicketListState = {
    statistics: any,
    searchFilter: any,
    loading: boolean
}

class AgentTicketList extends Component<any, AgentTicketListState> {
    constructor(props: any) {
        super(props);
        this.onSearchFilterApplied = this.onSearchFilterApplied.bind(this);
        this.state = {
            statistics: null,
            searchFilter: {
                ticketId: undefined,
                text: '',
                processedBy: null,
                requestedBy: null,
                from: null,
                until: null,
                status: [],
                priorities: [],
                themes: []
            },
            loading: true
        };
    }

    static areEqual(x: any, y: any) {
        if (x === y) return true;
        // if both x and y are null or undefined and exactly the same

        if (!(x instanceof Object) || !(y instanceof Object)) return false;
        // if they are not strictly equal, they both need to be Objects

        if (x.constructor !== y.constructor) return false;
        // they must have the exact same prototype chain, the closest we can do is
        // test there constructor.

        for (var p in x) {
            if (!x.hasOwnProperty(p)) continue;
            // other properties were tested using x.constructor === y.constructor

            if (!y.hasOwnProperty(p)) return false;
            // allows to compare x[ p ] and y[ p ] when set to undefined

            if (x[p] === y[p]) continue;
            // if they have the same strict value or identity then they are equal

            if (typeof (x[p]) !== "object") return false;
            // Numbers, Strings, Functions, Booleans must be strictly equal

            if (!AgentTicketList.areEqual(x[p], y[p])) return false;
            // Objects and Arrays must be tested recursively
        }

        for (p in y) {
            if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) return false;
            // allows x[ p ] to be set to undefined
        }
        return true;
    }

    async componentDidMount() {
        const data = await ticketService.getStatistics();
        this.setState({ statistics: data, loading: false });
    }

    onSearchFilterApplied(s: any) {
        if (!AgentTicketList.areEqual(s, this.state.searchFilter)) {
            searchFilterSettings.skip = 0;
            this.setState({ searchFilter: s });
        }
    }

    render() {
        const { t } = this.props;
        return (
            <Fragment>
                <Topbar />
                <ListInfobar />
                <div className={'main-wrapper'} >
                    <div className={'container'}>
                        {!this.state.loading && this.state.statistics &&
                            <div className="stat-cards">
                                <DoubleStatCard variant={'red'}>
                                    <div>
                                        <div className="stat-value"><strong>{this.state.statistics.newTickets.total}</strong></div>
                                        <div className="stat-label">{t("AgentTicketList.newRequestStatLabel")}</div>
                                    </div>
                                    <div>
                                        <span><FontAwesomeIcon icon={'angle-double-up'} size={'fw'} /></span>
                                        <span>
                                            <Trans i18nKey={"AgentTicketList.newRequestBlockingStatLabel"} values={{ count: this.state.statistics.newTickets.count }} />
                                        </span>
                                    </div>
                                </DoubleStatCard>
                                <DoubleStatCard variant={'blue'}>
                                    <div>
                                        <div className="stat-value"><strong>{this.state.statistics.waitingTickets.total}</strong></div>
                                        <div className="stat-label">{t("AgentTicketList.waitingRequestStatLabel")}</div>
                                    </div>
                                    <div>
                                        <span><FontAwesomeIcon icon={'angle-double-up'} size={'fw'} /></span>
                                        <span>
                                            <Trans i18nKey={"AgentTicketList.newRequestBlockingStatLabel"} values={{ count: this.state.statistics.waitingTickets.count }} />
                                        </span>
                                    </div>
                                </DoubleStatCard>
                                {(this.state.statistics.lastClosedTickets > 0) && <StatCard>
                                    <div className="stat-value">
                                        <Trans i18nKey={"AgentTicketList.closedRequestValue"} values={{ count: this.state.statistics.lastClosedTickets }} />
                                    </div>
                                    <div className="stat-label">
                                        <Trans i18nKey={"AgentTicketList.closedRequestLabel"} />
                                    </div>
                                </StatCard>}
                            </div>
                        }
                        <AgentTicketSearchFilter onSearchChange={this.onSearchFilterApplied} />
                        <AgentTicketGrid criteria={this.state.searchFilter} />
                    </div>
                </div>
            </Fragment>);
    }
}

registerForIntl(AgentTicketList);


export default withTranslation()(AgentTicketList);
