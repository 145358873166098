import React, { Component } from 'react';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import TicketStatus from '../TicketStatus';
import TicketPriority from '../TicketPriority';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import { withTranslation, WithTranslation } from 'react-i18next';

import agentService, { AgentService } from '../../services/AgentService';
import themeService from '../../services/ThemeService';

import ticketService from '../../services/TicketService';
import franchiseService, { FranchiseService } from '../../services/FranchiseService';
import searchFilterSettings, { SearchFilterSettings } from '../../services/SearchFilterSettings';
import Loader from '../../Loader';
import FontAwesomeIcon from '../../FontAwesome';

interface AgentTicketGridProps extends WithTranslation {
    criteria?: any;
}

type AgentTicketGridState = {
    tickets: any | null,
    loading: boolean,
    skip: number
}

class AgentTicketGrid extends Component<AgentTicketGridProps, AgentTicketGridState> {
    constructor(props: any) {
        super(props);
        this.state = {
            tickets: null,
            loading: true,
            skip: 0
        };
        this.pageChanged = this.pageChanged.bind(this);
        this.DateCell = this.DateCell.bind(this);
    }

    listOfCriteria: any[] = [];

    componentDidMount() {
        this.populate();
    }

    componentWillReceiveProps(nextProps: any) {
        if (this.state.loading) {
            this.listOfCriteria.push(nextProps.criteria);
        }
        else {
            this.setState({ loading: true });
            this.populate(nextProps.criteria);
        }
    }


    private async populate(criteria?: any | undefined) {
        let pagedResponse = await ticketService.getTickets(searchFilterSettings.skip, undefined, criteria ? criteria : this.props.criteria);
        while (this.listOfCriteria.length > 0) {
            const popped = this.listOfCriteria[this.listOfCriteria.length - 1];
            try {
                pagedResponse = await ticketService.getTickets(searchFilterSettings.skip, undefined, popped ? popped : this.props.criteria);
                this.listOfCriteria.length = 0;
            }
            catch {
                pagedResponse = null;
                this.listOfCriteria.length = 0;
            }
        }
        this.setState({ loading: false, tickets: pagedResponse, skip: searchFilterSettings.skip });
    }


    async pageChanged(e: any) {
        this.setState({ loading: true });
        try {
            const pagedResponse = await ticketService.getTickets(e.page.skip, e.page.take, this.props.criteria);
            searchFilterSettings.skip = e.page.skip;
            this.setState({ loading: false, tickets: pagedResponse, skip: searchFilterSettings.skip });
        }
        catch
        {
            this.setState({ loading: false, tickets: null, skip: 0 });
        }
    }

    StatusCell(props: any) {
        return (<td className={'text-center'}><TicketStatus status={props.dataItem.status} /></td>);
    }

    PriorityCell(props: any) {
        return (<td className={'text-center'}><TicketPriority priority={props.dataItem.priority} /></td>);
    }

    EditCell(props: any) {
        return (<td className={'text-center'}><Link className="active" to={'/ticket/' + props.dataItem.id}><FontAwesomeIcon className={'text-primary'} icon={'pencil'} size={'fw'} /></Link></td>);
    }

    DateCell(props: any) {
        const { t } = this.props;
        return (<td className={'text-center'}><Moment date={props.dataItem[props.field]} format={t('moment.dateFormat')} /></td>)
    }

    ThemeCell(props: any) {
        const def = themeService.get(props.dataItem.theme);
        return (<td className={'text-center'}>{def ? def.label : ''}</td>);
    }

    ProcessedByCell(props: any) {
        let agent = null;
        if (props.dataItem.processedBy) {
            agent = AgentService.formatUserName(agentService.findAgent(props.dataItem.processedBy));
        }
        return (<td>{agent}</td>);

    }

    RequestedByCell(props: any) {
        let franchise = null;
        if (props.dataItem.requestedBy) {
            franchise = FranchiseService.formatUserName(franchiseService.findFranchise(props.dataItem.requestedBy));
        }
        return (<td>{franchise}</td>);

    }

    render() {
        const { t } = this.props;

        if (this.state.loading) {
            return (<Loader />);
        }
        else if (this.state.tickets && this.state.tickets.items && this.state.tickets.items.length > 0) {
            return (
                <Grid className={'tickets-grid mb-4'} data={this.state.tickets.items} pageable={true} skip={searchFilterSettings.skip} total={this.state.tickets.totalCount} pageSize={SearchFilterSettings.pageSize} style={{ height: '430px' }} onPageChange={this.pageChanged}>
                    <GridColumn field="id" title={t('AgentTicketGrid.idColumn')} width={'70px'} sortable={true} />
                    <GridColumn field="priority" title={t('AgentTicketGrid.priorityColumn')} cell={this.PriorityCell} width={'70px'} />
                    <GridColumn field="subject" title={t('AgentTicketGrid.subjectColumn')} sortable={true} />
                    <GridColumn field="status" title={t('AgentTicketGrid.statusColumn')} cell={this.StatusCell} width={'140px'} />
                    <GridColumn field="processedBy" title={t('AgentTicketGrid.processedByColumn')} cell={this.ProcessedByCell} />
                    <GridColumn field="creationTime" title={t('AgentTicketGrid.creationTimeColumn')} cell={this.DateCell} width={'100px'} />
                    <GridColumn field="requestedBy" title={t('AgentTicketGrid.requestedByColumn')} cell={this.RequestedByCell} />
                    <GridColumn field="theme" title={t('AgentTicketGrid.themeColumn')} cell={this.ThemeCell} />
                    <GridColumn title={t('AgentTicketGrid.commandColumn')} cell={this.EditCell} width={'45px'} />
                </Grid>
            );
        }
        else if (this.state.tickets !== null) {
            return (<div className={'container text-center'}>{t('AgentTicketGrid.noTicket')}</div>);
        }
        else {
            return (<div className={'container'}>{t("AgentTicketGrid.internalError")}</div>);
        }


    }
}

export default withTranslation()(AgentTicketGrid);

