import React, { Component } from 'react';
import { Card, Row, Col, Accordion } from 'react-bootstrap';
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import { TicketStatusDefinitions, TicketPriorityDefinitions, internationalize } from '../../Enums'
import themeService from '../../services/ThemeService';
import searchFilterSettings from '../../services/SearchFilterSettings';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import agentService, { AgentService } from '../../services/AgentService';
import franchiseService, { FranchiseService } from '../../services/FranchiseService';
import { withTranslation, WithTranslation } from 'react-i18next';
import FontAwesomeIcon from '../../FontAwesome';
import { registerForIntl } from '@progress/kendo-react-intl';
import AgentList from '../../AgentList';
import FranchiseList from '../../FranchiseList';
import authenticationService from '../../services/AuthenticationService';

interface SearchFilterProps extends WithTranslation {
    onSearchChange?: Function;
}

type SearchFilterState = {
    ticketId: number | undefined,
    text: string,
    processedBy: number | null,
    requestedBy: number | null,
    from: Date | null,
    until: Date | null,
    status: Array<any>,
    priorities: Array<any>,
    themes: Array<any>,
    agents: Array<any>,
    franchises: Array<any>,
    requester: any,
    agent: any
}

class AgentTicketSearchFilter extends Component<SearchFilterProps, SearchFilterState> {
    static readonly defaultUser = { id: null, displayName: '' };
    static readonly fullTextSearchMinLen : number = 3;
    timer: any = null;
    constructor(props: any) {
        super(props);
        const { t } = this.props;
        internationalize(TicketPriorityDefinitions, 'Priority.label', t);
        internationalize(TicketStatusDefinitions, 'Status', t);
        const status = searchFilterSettings.status;
        if (status.length === 0) {
            status.push(TicketStatusDefinitions[0]);
            status.push(TicketStatusDefinitions[1]);
            status.push(TicketStatusDefinitions[2]);
            status.push(TicketStatusDefinitions[3]);
            status.push(TicketStatusDefinitions[5]);
            status.push(TicketStatusDefinitions[6]);
        }
        this.state = {
            ticketId: searchFilterSettings.ticketId,
            text: searchFilterSettings.text,
            processedBy: searchFilterSettings.processedBy,
            requestedBy: searchFilterSettings.requestedBy,
            from: searchFilterSettings.from,
            until: searchFilterSettings.until,
            status: searchFilterSettings.status,
            priorities: searchFilterSettings.priorities,
            themes: searchFilterSettings.themes,
            agents: [],
            franchises: [],
            requester: AgentTicketSearchFilter.defaultUser,
            agent: AgentTicketSearchFilter.defaultUser
        };
        this.onFreeSearchChanged = this.onFreeSearchChanged.bind(this);
        this.onHandleReturnKey = this.onHandleReturnKey.bind(this);
        this.setTicketId = this.setTicketId.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleReset = this.handleReset.bind(this);
        this.selectMe = this.selectMe.bind(this);
    }

    async componentDidMount() {
        const agents = await agentService.getAgents();
        const franchises = await franchiseService.getFranchises();
        this.setState(
            {
                agents: agents.sort(AgentService.compare).map((u: any) => { return { id: u.id, displayName: AgentService.formatUserName(u) }; }),
                franchises: franchises.sort(FranchiseService.compare).map((u: any) => { return { id: u.id, displayName: FranchiseService.formatUserName(u) }; }),
            });
        let agent = AgentTicketSearchFilter.defaultUser;
        let franchise = AgentTicketSearchFilter.defaultUser;
        if (this.state.processedBy || this.state.requestedBy) {
            if (this.state.processedBy) {
                agent = this.state.agents.find((a: any) => a.id === this.state.processedBy);
                if (!agent) {
                    agent = AgentTicketSearchFilter.defaultUser;
                }
            }
            if (this.state.requestedBy) {
                franchise = this.state.franchises.find((a: any) => a.id === this.state.requestedBy);
                if (!franchise) {
                    franchise = AgentTicketSearchFilter.defaultUser;
                }
            }
            this.setState(
                {
                    agent: agent,
                    requester: franchise
                });
        }

        this.handleSearch();
    }

    onFreeSearchChanged(event: any) {
        this.setState({ text: event.target.value });
    }

    onHandleReturnKey(event: any) {
        if(event.key === 'Enter') {
            this.handleSearch();
        }
    }

    handleSearch() {
        if(! this.hasTicketId() && ! this.isFreeSearchValid())
        {
            return;
        }

        if (this.props.onSearchChange) {
            searchFilterSettings.ticketId = this.state.ticketId;
            searchFilterSettings.text = this.state.text;
            searchFilterSettings.processedBy = this.state.processedBy;
            searchFilterSettings.requestedBy = this.state.requestedBy;
            searchFilterSettings.from = this.state.from;
            searchFilterSettings.until = this.state.until;
            searchFilterSettings.status = this.state.status;
            searchFilterSettings.priorities = this.state.priorities;
            searchFilterSettings.themes = this.state.themes;
            this.props.onSearchChange(
                {
                    ticketId: this.state.ticketId,
                    text: this.state.text,
                    processedBy: this.state.processedBy,
                    requestedBy: this.state.requestedBy,
                    from: this.state.from,
                    until: this.state.until,
                    status: this.state.status.map((o) => o.id),
                    priorities: this.state.priorities.map((o) => o.id),
                    themes: this.state.themes.map((o) => o.id)
                }
            );
        }
    }

    handleReset() {
        searchFilterSettings.reset();
        const status = [TicketStatusDefinitions[0], TicketStatusDefinitions[1], TicketStatusDefinitions[2], TicketStatusDefinitions[3], TicketStatusDefinitions[5], TicketStatusDefinitions[6]];
        this.setState({
            ticketId: undefined,
            text: '',
            processedBy: null,
            requestedBy: null,
            agent: AgentTicketSearchFilter.defaultUser,
            requester: AgentTicketSearchFilter.defaultUser,
            from: null,
            until: null,
            status: status,
            priorities: [],
            themes: []
        },
            () => { this.handleSearch(); }
        );
    }

    selectMe(e:any) {
        e.preventDefault();
        this.setState({processedBy:authenticationService.me().id}, () => this.handleSearch());
    }

    isFreeSearchValid() {
        return this.state.text.length === 0 || (this.state.text.length >= AgentTicketSearchFilter.fullTextSearchMinLen);
    }


    setTicketId(event: any) {
        const ticketId = event.target.value && (event.target.value !== '') ? parseInt(event.target.value) : undefined; 
        this.setState({ ticketId: ticketId });
    }

    hasTicketId(): boolean {
        return (this.state.ticketId !== undefined) && (this.state.ticketId > 0);
    }

    render() {
        const { t } = this.props;

        return (
            <Accordion>
                <Card className={'card-filters'}>
                    <Card.Header>{t('searchfilter.title')}</Card.Header>
                    <Card.Body>
                        <Row>
                            <Col md={3}>
                                <div className="form-group">
                                    <label>{t('searchfilter.processedBy')}</label><a className={'float-right'} href="/me" onClick={this.selectMe}>{t('searchfilter.me')}</a>
                                    <AgentList
                                        disabled={this.hasTicketId()}
                                        required={false}
                                        userId={this.state.processedBy}
                                        onChange={(user: any) => { this.setState({ agent: user, processedBy: user?.id }) }}
                                    />
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className="form-group">
                                    <label>{t('searchfilter.priority')}</label>
                                    <MultiSelect className="js-multiselect" data={TicketPriorityDefinitions} textField={'label'} dataItemKey={'id'}
                                        disabled={this.hasTicketId()}
                                        value={this.state.priorities} onChange={(e: any) => { this.setState({ priorities: e.target.value }); }} />
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className="form-group">
                                    <label>{t('searchfilter.requestedBy')}</label>
                                    <FranchiseList
                                        required={false}
                                        disabled={this.hasTicketId()}
                                        userId={this.state.requestedBy}
                                        onChange={(user: any) => { this.setState({ requester: user, requestedBy: user?.id }) }}
                                    />
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className="form-group">
                                    <label>{t('searchfilter.freeSearch')}</label>
                                    <input type="text" className={'form-control' + ((this.hasTicketId() || this.isFreeSearchValid()) ? '' : ' is-invalid')}
                                        disabled={this.hasTicketId()}
                                        onKeyUp={this.onHandleReturnKey}
                                        value={this.state.text} onChange={this.onFreeSearchChanged} />
                                    <div className="invalid-feedback">{t('Error.tooShortString', { len: AgentTicketSearchFilter.fullTextSearchMinLen })}</div>
                                </div>
                            </Col>
                        </Row>
                        <Accordion.Collapse eventKey="0">
                            <div className="advanced-search">
                                <div className="section-title"><FontAwesomeIcon icon={'search-plus'} size={'fw'} /> &nbsp;{t('searchfilter.advancedFilter')}</div>
                                <Row>
                                    <Col md={4}>
                                        <div className="form-group">
                                            <label>{t('searchfilter.status')}</label>
                                            <MultiSelect className="js-multiselect" data={TicketStatusDefinitions} textField={'label'} dataItemKey={'id'}
                                                disabled={this.hasTicketId()}
                                                value={this.state.status} onChange={(e: any) => { this.setState({ status: e.target.value }); }} />
                                        </div>
                                    </Col>
                                    <Col md={3}>
                                        <div className="form-group">
                                            <label>{t('searchfilter.theme')}</label>
                                            <MultiSelect className="js-multiselect" data={themeService.Themes()} textField={'label'} dataItemKey={'id'}
                                                disabled={this.hasTicketId()}
                                                value={this.state.themes} onChange={(e: any) => { this.setState({ themes: e.target.value }); }} />

                                        </div>
                                    </Col>
                                    <Col md={2}>
                                        <div className="form-group">
                                            <label>{t('searchfilter.ticketId')}</label>
                                            <input type="number" className="form-control"
                                                onKeyUp={this.onHandleReturnKey}
                                                value={this.state.ticketId ?? ''} onChange={this.setTicketId} />
                                                
                                        </div>
                                    </Col>
                                    <Col md={3}>
                                        <div className="form-group">
                                            <label>{t('searchfilter.creationDate')}</label>
                                            <div className="form-group">
                                                <div className="input-group flex-nowrap">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text">{t('searchfilter.from')}</span>
                                                    </div>
                                                    <DatePicker className={'form-control'}
                                                        value={this.state.from}
                                                        disabled={this.hasTicketId()}
                                                        onChange={(e: any) => { this.setState({ from: e.target.value }); }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <div className="input-group flex-nowrap">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text">{t('searchfilter.until')}</span>
                                                    </div>
                                                    <DatePicker className={'form-control'}
                                                        value={this.state.until}
                                                        disabled={this.hasTicketId()}
                                                        onChange={(e: any) => { this.setState({ until: e.target.value }); }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Accordion.Collapse>
                    </Card.Body>
                    <Card.Footer>
                        <Accordion.Toggle data-toggle="collapse" className="btn btn-light" eventKey="0">
                            <FontAwesomeIcon icon={'search-plus'} size={'fw'} /><span className="d-none d-sm-inline-block"> {t('searchfilter.advancedFilter')}</span>
                        </Accordion.Toggle>
                        <span className="ml-auto"></span>
                        <button type="reset" className="btn btn-light" onClick={(e) => this.handleReset()}><FontAwesomeIcon icon={'times'} size={'fw'} /><span className="d-none d-sm-inline-block"> {t('searchfilter.reset')}</span></button>
                        <button type="submit" disabled={!this.hasTicketId() && !this.isFreeSearchValid()} className="btn btn-primary" onClick={(e) => this.handleSearch()}><FontAwesomeIcon icon={'check'} size={'fw'} /><span className="d-none d-sm-inline-block"> {t('searchfilter.apply')}</span></button>
                    </Card.Footer>
                </Card>
            </Accordion>
        );
    }
}

registerForIntl(AgentTicketSearchFilter);

export default withTranslation()(AgentTicketSearchFilter);
