import authenticationService from './AuthenticationService';
import jsonService from './jsonService';
type User = {
    id: number,
    lastName: string,
    firstName: string,
    countryCode : number
}

export class FranchiseService {
    private _franchises = null;
    reset() {
        this._franchises = null;
    }
    static compare(aUser: User, bUser: User) {
        let bandA = aUser.lastName.toUpperCase();
        let bandB = bUser.lastName.toUpperCase();

        let comparison = 0;
        if (bandA > bandB) {
            comparison = 1;
        } else if (bandA < bandB) {
            comparison = -1;
        }
        else {
            bandA = aUser.firstName.toUpperCase();
            bandB = bUser.firstName.toUpperCase();
            if (bandA > bandB) {
                comparison = 1;
            } else if (bandA < bandB) {
                comparison = -1;
            }
        }
        return comparison;
    }

    static formatUserName(user: any) {
        if (user) {
            return user.lastName + ", " + user.firstName;
        }
        else {
            return '';
        }
    }

    async getFranchises() {
        if (this._franchises) {
            return this._franchises;
        }
        if (authenticationService.isAuthenticated()) {

            const response = await jsonService.get('/users/franchises', authenticationService.prepareRequest({}));
            const data = await response.json();
            this._franchises = data;
            return data;
        }
        else {
            this._franchises = null;
            return [];
        }
    }


    findFranchise(id: number | null) {
        if (id && this._franchises) {
            const data = this._franchises || [];
            return data.find((u: User) => u.id === id);
        }
        return undefined;
    }
}

const franchiseService = new FranchiseService();
export default franchiseService;