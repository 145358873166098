import React, { Component } from 'react';
import Chart from 'chart.js';

Chart.pluginService.register({
    id: 'center',
    beforeDraw: function (chart: any) {
        const centerConfig = chart.options.plugins?.center?.options;
        if (centerConfig) {
            //Get ctx from string
            var ctx = chart.chart.ctx;

            //Get options from the center object in options
            var fontStyle = centerConfig.fontStyle || 'Arial';
            var txt = centerConfig.text;
            var color = centerConfig.color || '#000';
            var sidePadding = centerConfig.sidePadding || 20;
            var sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2)
            //Start with a base font of 30px
            ctx.font = "30px " + fontStyle;

            //Get the width of the string and also the width of the element minus 10 to give it 5px side padding
            var stringWidth = ctx.measureText(txt).width;
            var elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;

            // Find out how much the font can grow in width.
            var widthRatio = elementWidth / stringWidth;
            var newFontSize = Math.floor(30 * widthRatio);
            var elementHeight = (chart.innerRadius * 2);

            // Pick a new font size so it will not be larger than the height of label.
            var fontSizeToUse = Math.min(newFontSize, elementHeight);

            //Set font settings to draw it correctly.
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            var centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
            var centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
            ctx.font = fontSizeToUse + "px " + fontStyle;
            ctx.fillStyle = color;

            //Draw text in center
            ctx.fillText(txt, centerX, centerY);
        }
    }
});

// type DataSet = {
//     title: string,
//     values: any[],
//     colors?: string[]
// }

type PieChartProps = {
    variant?: 'pie' | 'doughnut' | 'radar'
    className?: string,
    labels: string[],
    title?: string,
    data: any
}

// function getRandomColor() {
//     var letters = '0123456789ABCDEF';
//     var color = '#';
//     for (var i = 0; i < 6; i++) {
//         color += letters[Math.floor(Math.random() * 16)];
//     }
//     return color;
// }

export default class PieChart extends Component<PieChartProps> {

    chartRef: any = null;
    myChart: any = null;
    // static colors =['#00429d', '#204fa3', '#325da9', '#406aaf', '#4e78b5', '#5a86bb', '#6694c1', '#73a2c6', '#80b1cc', '#8ebfd1', '#9dced6', '#addcda', '#c0eade', '#d8f6e1', '#ffffe0'];
    static colors =['#F06292', '#BA68C8', '#9575CD', '#7986CB', '#64B5F6', '#4DD0E1', '#6694c1', '#4DB6AC', '#81C784', '#DCE775', '#FFD54F', '#FFB74D', '#FF8A65', '#A1887F', '#90A4AE'];
    
    constructor(props: any) {
        super(props);
        this.chartRef = React.createRef();
    }

    componentDidUpdate(newProps: any) {
        this.myChart.data.labels = this.props.labels;
        this.myChart.data.datasets = [{ backgroundColor: PieChart.colors, data: this.props.data?.values }];
        this.myChart.options.plugins.center.options.text = this.props.data?.title || '';
        this.myChart.update();
    }

    componentDidMount() {
        this.myChart = new Chart(this.chartRef.current, {
            type: this.props.variant || 'pie',
            options: {
                legend: {
                    display: false,
                    onClick: (e) => e.stopPropagation()
                },
                plugins: {
                    center:
                    {
                        options: {
                            text: this.props.data?.title || '',
                            color: '#4C455F', //Default black
                            fontStyle: 'Helvetica', //Default Arial
                            sidePadding: 50 //Default 20 (as a percentage)
                        }
                    }
                }
            },
            data: {
                labels: this.props.labels,
                datasets: [{ data: this.props.data?.values, backgroundColor: PieChart.colors }]
            }
        });
    }

    render() {
        return (
            <canvas className={this.props.className} width="100%" height="100%" ref={this.chartRef} />
        );
    }
}