import React, {Component} from 'react';
import FranchiseEditTicketForm from './franchise/FranchiseEditTicketForm';
import AgentEditTicketForm from './agent/AgentEditTicketForm';
import NotFound from '../NotFound';

import authenticationService from '../services/AuthenticationService';
import { Redirect } from 'react-router';

type EditTicketFormProps = {
    id: number
}

export default class EditTicketForm extends Component<EditTicketFormProps, any> {
    componentWillMount() {
        document.body.className = "page-ticket infobar-fixed";
    }

    render() {
        const me = authenticationService.me();
        if(me && me.role === 'agent') {
            return (<AgentEditTicketForm id={this.props.id} />);
        }
        else if(me && me.role === 'franchise') {
            return (<FranchiseEditTicketForm id={this.props.id} />);
        }
        else if(authenticationService.isAuthenticated()) {
            return (<NotFound component={'EditTicketForm#'+this.props.id}/>);
        }
        else {
            return (<Redirect to={'/login?q=' + encodeURIComponent('/ticket/'+this.props.id)} />);
        }
    }
}