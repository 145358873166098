import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { LocalizationProvider, IntlProvider } from '@progress/kendo-react-intl';
import { withTranslation, WithTranslation } from 'react-i18next';

import AgentTicketList from './agent/AgentTicketList';
import FranchiseTicketList from './franchise/FranchiseTicketList';

import authenticationService from '../services/AuthenticationService';
import agentService from '../services/AgentService';
import franchiseService from '../services/FranchiseService';

import Loader from '../Loader';
import themeService from '../services/ThemeService';

type TicketListState = {
    loading: boolean
}

class TicketList extends Component<any & WithTranslation, TicketListState> {
    constructor(props: any) {
        super(props);
        this.state = {
            loading: true,
        };
    }

    componentWillMount() {
        document.body.className = "page-ticket";
    }

    componentDidMount() {
        if (this.state.loading) {
            this.populate();
        }
    }

    private async populate() {
        await agentService.getAgents();
        await franchiseService.getFranchises();
        await themeService.getThemes();
        this.setState({ loading: false });
    }

    render() {
        if (!authenticationService.isAuthenticated()) {
            return (<Redirect to={'/login'} />);
        }
        else if (this.state.loading) {
            return (<Loader />);
        }
        else {
            const { i18n } = this.props;

            const me = authenticationService.me();
            if (me && me.role === 'agent') {
                return (
                    <LocalizationProvider language={i18n.language}>
                        <IntlProvider locale={i18n.language} >
                            <AgentTicketList />
                        </IntlProvider>
                    </LocalizationProvider>);
            }
            else if (me && me.role === 'franchise') {
                return (<LocalizationProvider language={i18n.language}>
                    <IntlProvider locale={i18n.language}>
                        <FranchiseTicketList />
                    </IntlProvider>
                </LocalizationProvider>);
            }
            else {
                return (<Redirect to={'/login'} />);
            }
        }
    }
}

export default withTranslation()(TicketList);