import jsonService from './jsonService';
import authenticationService from './AuthenticationService';
import i18n from "i18next";
export class ThemeService {
    
    private _themes : any[] | undefined = undefined;
    
    reset() {
        this._themes = undefined;
    }

    Themes() {
        return this._themes;
    }
    
    async getThemes() {
        if(this._themes) {
            return this._themes;
        }

        const response = await jsonService.get('/settings/themes',authenticationService.prepareRequest({}));
        const data : any = await response.json();
        data.sort((a : any,b : any) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
        this._themes = data;
        return data;       
    }
    
    first() {
        var list = this._themes;
        if(list !== undefined)
        {
            return list[0];
        }
        return null;     
    }

    get(id: number) {
        if(id === 0)
        {
            return {id: 0, label : i18n.t('Theme.undefined')};
        }
        var list = this._themes;
        if(list !== undefined)
        {
            return list.find((item:any) => item.id === id);
        }
        return null;
    }

}

const themeService = new ThemeService();
export default themeService;