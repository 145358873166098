import React, { Component } from 'react';

type IconProps = {
    size?: 'fw' | 'xs' | 'sm' | 'lg' | '2x' | '3x' | '4x' | '5x' | '6x' | '7x' | '8x' | '9x' | '10x',
    flip?: 'horizontal' | 'vertical' | 'both',
    rotate?: '90' | '180' | '270',
    animation?: 'spin' | 'pulse',
    variant? : 'solid' | 'regular'
    className?: string,
    icon: string
}

export default class FontAwesomeIcon extends Component<IconProps, any> {
    render() {
        let className = 'fa';
        if(this.props.variant)
        {
            if(this.props.variant === 'solid') {
                className = 'fas';
            }
            else if(this.props.variant === 'regular') {
                className = 'far';
            }
        }
        className = className + ' fa-' + this.props.icon;
        if (this.props.size) {
            className = className + ' fa-' + this.props.size;
        }
        if (this.props.flip) {
            className = className + ' fa-flip-' + this.props.flip;
        }
        if (this.props.rotate) {
            className = className + ' fa-rotate-' + this.props.rotate;
        }
        if (this.props.animation) {
            className = className + ' fa-' + this.props.animation;
        }
        if (this.props.className && this.props.className !== '') {
            className = className + ' ' + this.props.className;
        }

        return (<i aria-hidden={true} className={className}>{this.props.children}</i>);
    }

}