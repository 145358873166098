import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import FontAwesomeIcon from '../FontAwesome';

interface NewTicketInfobarProps extends WithTranslation {
    canSave: boolean;
    onSave?: Function;
    onCancel?: Function;
}

class NewTicketInfobar extends Component<NewTicketInfobarProps, any> {
    constructor(props: any) {
        super(props);
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    onSave(e: any) {
        e.preventDefault();
        if (this.props.onSave) {
            this.props.onSave();
        }
    }

    onCancel(e: any) {
        e.preventDefault();
        if (this.props.onCancel) {
            this.props.onCancel();
        }
    }


    render() {
        const { t } = this.props;
        return (
            <div className="infobar">
                <div className="container">
                    <div className="infos">
                        <span className="icon"><FontAwesomeIcon icon={'list'} size={'fw'}/></span>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item active">{t('NewTicketInfobar.newRequest')}</li>
                        </ol>
                    </div>
                    <div className="actions">
                        <button className="btn btn-outline-light" onClick={this.onCancel}><FontAwesomeIcon icon={'close'} size={'fw'}/><span className="d-none d-sm-inline-block"> {t('NewTicketInfobar.cancel')}</span></button>
                        <button className={'btn btn-primary'} disabled={!this.props.canSave} onClick={this.onSave}><FontAwesomeIcon icon={'check'} size={'fw'}/><span className="d-none d-sm-inline-block"> {t('NewTicketInfobar.save')}</span></button>
                    </div>
                </div>
            </div>
        );
    }
}


export default withTranslation()(NewTicketInfobar);