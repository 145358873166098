import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";
import {loadMessages, load} from '@progress/kendo-react-intl';

import translationEN from '../locales/en.json';
import translationFR from '../locales/fr.json';
import KendoFR from '../locales/kendo-fr.json';


load( 
  require('cldr-core/supplemental/weekData.json'), 
  require('cldr-dates-full/main/fr/ca-gregorian.json'), 
  require('cldr-dates-full/main/fr/dateFields.json')
);


const resources = {
  'en': { translation: translationEN },
  'fr': { translation: translationFR }
};
i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    whitelist: ['fr', 'en'],
    fallbackLng: 'en',
    react: {
      transSupportBasicHtmlNodes: true, // allow <br/> and simple html elements in translations
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'b', 'p', 'small'] // don't convert to <1></1> if simple react elements
    },
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

loadMessages(KendoFR, 'fr');

export default i18n;