import React, { Component } from 'react';
import themeService from '../services/ThemeService';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { withTranslation, WithTranslation } from 'react-i18next';

type ThemeListProps = {
    value?: any,
    allowNull?: boolean,
    error?: string | null,
    onChange?: Function
}
type ThemeListState = {
    themes: any[] | undefined,
    value: any | undefined
}
export class ThemeList extends Component<ThemeListProps & WithTranslation, any> {

    private themes: any[] | undefined = undefined;

    constructor(props: any) {
        super(props);
        this.state = {
            themes: undefined,
            value : this.props.value
        };
        this.valueChanged = this.valueChanged.bind(this);
    }


    valueChanged(e: any) {
        if (this.props.onChange) {
            this.props.onChange(e.target.value);
        }
    }

    componentDidUpdate(newProps : any) {
        if(newProps.value && this.props.value) {
            if(newProps.value.id !== this.props.value.id) {
                this.setState({value : this.props.value});
            }
        }
        else if(newProps.value || this.props.value)
        {
            this.setState({value : this.props.value});
        }
    }

    async componentWillMount() {
        const {t} = this.props;
        let themes : any[] = await themeService.getThemes();
        let value = this.state.value;
        if(this.props.allowNull) {
            themes = [{id:0, label:t('Theme.all')}].concat(themes);
            if(! value)
            {
                value = themes[0];
            }
        }
        this.setState({themes: themes, value : value});
    }

    render() {
        const validityClassName : string = (this.props.error === undefined || this.props.error === null) ? '' : ' is-invalid';
        return (
            <>
        <DropDownList className={'form-control' + validityClassName} data={this.state.themes} textField={'label'} dataItemKey={'id'}
            value={this.state.value}
            onChange={this.valueChanged} />
        <div className="invalid-feedback">{this.props.error}</div>
        </>);

    }
}

export default withTranslation()(ThemeList)