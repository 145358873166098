import React, { Component } from 'react';
import Moment from 'react-moment';
import {TicketStatusDefinitions} from '../Enums';

type TimeLineProps = {
    data: any
}

type TimeLineItemProps = {
    item: any
}

class TimeLineItem extends Component<TimeLineItemProps, any> {
    render() {
        let icon = "fa-step-forward";
        let sticker = "sticker-state";
        let showState = true;
        if (this.props.item.status === 1) {
            sticker = "sticker-add";
            icon = "fa-plus";
            showState = false;
        }
        else if ((this.props.item.status === 8) || (this.props.item.status === 5)) {
            sticker = "sticker-closed";
            icon = "fa-check";
            showState = false;
        }
        const statusDef = TicketStatusDefinitions.find((e) => e.id === this.props.item.status);

        return (
            <div className={'timeline-item'}>
                <div className="left">
                    <div>
                        <span className="date">Le <Moment date={this.props.item.when} format={'DD/MM/YYYY à HH:mm'} /></span>
                        {this.props.item.author}
                    </div>
                </div>
                <div className="middle">
                    <div>
                        <span className={'sticker ' + sticker}><i className={'fa fa-fw ' + icon} aria-hidden={true}></i></span>
                    </div>
                </div>

                {showState && <div className="right with-state ">
                    <div>
                        <div className="part part-state">
                            <div className="part-title">
                                Nouvel état :
                                </div>
                            <div className="part-content">
                                <span className="badge badge-secondary">{statusDef?.label}</span>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
        );
    }
}

export default class TimeLine extends Component<TimeLineProps, any> {
    render() {
        let items : any[] = this.props.data;
        items.sort(function(a : any, b: any) { 
            let dateA : any = new Date(a.when);
            let dateB : any = new Date(b.when);
            if(dateA > dateB) {
                return -1;
            }
            else if(dateA < dateB)
            {
                return 1;
            }
            else {
                return 0;
            }
        });
        return (
            <div className={'timeline'}>
                {
                    items.map((i: any, index: number) => <TimeLineItem key={index} item={i} />)
                }
            </div>
        );
    }
}