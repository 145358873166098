import React, { Component, Fragment } from 'react';
import Topbar from '../../Topbar';
import ListInfobar from '../ListInfobar';
import FranchiseTicketGrid from './FranchiseTicketGrid';
import { withTranslation } from 'react-i18next';

class FranchiseTicketList extends Component<any> {
    render() {
        const { t } = this.props;
        return (
            <Fragment>
                <Topbar />
                <ListInfobar />
                <div className="main-wrapper">
                    <div className="container">
                        <h2 className="title">{t('FranchiseTicketList.myRequests')}</h2>
                        <FranchiseTicketGrid/>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default withTranslation()(FranchiseTicketList);
