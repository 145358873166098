import React, { Component } from 'react';
import { MetaContainer, MetaField } from '../MetaDataItems'
import MetaDateTimeField from '../MetaDateTimeField';
import Topbar from '../../Topbar';
import EditTicketInfobar from '../EditTicketInfobar';
import { TicketStatusDefinitions, TicketPriorityDefinitions, internationalize } from '../../Enums'
import { Tab, Row, Col, Card } from 'react-bootstrap';
import TimeLine from '../TimeLine';
import Attachment from '../Attachment';
import AttachmentList from '../AttachmentList';
import ConfirmDialog from '../ConfirmDialog';

import ticketService, { TicketService } from '../../services/TicketService';
import franchiseService, { FranchiseService } from '../../services/FranchiseService';
import themeService from '../../services/ThemeService';
import StringUtils from '../../services/StringUtils';

import CommentList from '../CommentList';
import { Redirect } from 'react-router';
import { Upload, UploadFileStatus } from '@progress/kendo-react-upload';
import Loader from '../../Loader';
import { ServiceUri } from '../../services/ServicesUri';
import authenticationService from '../../services/AuthenticationService';
import { withTranslation, WithTranslation, Trans } from 'react-i18next';
import { Input } from '@progress/kendo-react-inputs';

type FranchiseEditTicketFormProps = {
    id: number
}

type FranchiseEditTicketFormState = {
    loading: boolean,
    closing: boolean,
    isValid: boolean,
    subject: string,
    description: string,
    creationTime: Date | null,
    closingTime: Date | null,
    theme: any,
    status: any,
    priority: any,
    requestedBy: number,
    requester: any,
    processedBy: number | null,
    comments: Array<any>
    comment: string,
    timeline: any,
    attachmentToDelete: any | undefined,
    attachments: Array<any>
    files : Array<any>,
    saved: boolean,
    errorCode: string | null
}

class FranchiseEditTicketForm extends Component<FranchiseEditTicketFormProps & WithTranslation, FranchiseEditTicketFormState> {
    constructor(props: any) {
        super(props);
        const { t } = this.props;
        internationalize(TicketPriorityDefinitions, 'Priority.label', t);
        internationalize(TicketPriorityDefinitions, 'Priority.description', t, 'description');
        internationalize(TicketStatusDefinitions, 'Status', t);
        this.state = {
            loading: true,
            closing: false,
            creationTime: null,
            isValid: true,
            saved: false,
            subject: '',
            description: '',
            closingTime: null,
            theme: null,
            status: null,
            priority: null,
            requestedBy: 0,
            requester: null,
            processedBy: null,
            timeline: null,
            comment: '',
            attachments: [],
            files: [],
            attachmentToDelete: undefined,
            comments: [],
            errorCode: null
        }
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onUpdateFiles = this.onUpdateFiles.bind(this);
        this.onCloseTicket = this.onCloseTicket.bind(this);
        this.doCloseTicket = this.doCloseTicket.bind(this);
        this.cancelCloseTicket = this.cancelCloseTicket.bind(this);
        this.onDeleteAttachment = this.onDeleteAttachment.bind(this);
        this.doDeleteAttachment = this.doDeleteAttachment.bind(this);
        this.cancelDeleteAttachment = this.cancelDeleteAttachment.bind(this);
    }

    async componentDidMount() {
        const ticket = await ticketService.getTicket(this.props.id);
        await themeService.getThemes();
        await franchiseService.getFranchises();

        const requester = await franchiseService.findFranchise(ticket.requestedBy);
        this.setState({
            loading: false,
            subject: ticket.subject,
            creationTime: ticket.creationTime,
            closingTime: ticket.closingTime,
            comments: ticket.comments,
            description: ticket.description,
            theme: themeService.get(ticket.theme),
            priority: TicketPriorityDefinitions.find((item) => item.id === ticket.priority),
            status: TicketStatusDefinitions.find((item) => item.id === ticket.status),
            processedBy: ticket.processedBy,
            timeline: ticket.timeline,
            attachments: ticket.attachments || [],
            requestedBy: ticket.requestedBy,
            requester: requester
        })
    }

    onCancel() {
        var files = this.state.files;
        if(files.some((file) => file.status == UploadFileStatus.Uploading))
        {
            return;
        }
        this.setState({ loading: false, saved: true });
    }



    async onSave() {
        if (!authenticationService.isAuthenticated()) {
            this.setState({ loading: false, saved: true });
            return;
        }

        this.setState({ loading: true, errorCode: null });

        const ticket = {
            id: this.props.id,
            subject: null,
            description: null,
            theme: this.state.theme.id,
            status: this.state.status.id,
            priority: this.state.priority.id,
            requestedBy: this.state.requestedBy,
            processedBy: this.state.processedBy,
            newComment: StringUtils.isWhiteSpace(this.state.comment) ? null : this.state.comment
        };
        try {
            await ticketService.updateTicket(ticket);
            var hasFilesToUpload = this.state.files.length !== 0 && this.state.files.some(file => file.status !== UploadFileStatus.Uploaded);
            if(hasFilesToUpload)
            {
                const files = this.state.files;
                files.forEach((file : any, index: number, array: any[]) => {
                    if(file.status !== UploadFileStatus.Uploading && file.status !== UploadFileStatus.Uploaded)
                    {
                        file.status = UploadFileStatus.Uploading;
                        this.setState({ files : array});
                        ticketService.addAttachment(this.props.id, file.getRawFile()).then(() => 
                        {
                            file.status = UploadFileStatus.Uploaded;
                            var allfilesUploaded = array.every(value => file.status === UploadFileStatus.Uploaded);
                            this.setState({ files : array, saved : allfilesUploaded });
                        });
                    }
                });
            }
            this.setState({ saved: ! hasFilesToUpload, loading: false });
        }
        catch
        {
            this.setState({ saved: false, errorCode: 'internalError', loading: false });
        }
    }
    
    onUpdateFiles(e: any) {
        this.setState({ files: e.newState });
    }

    onBeforeUpload(e: any) {
        e.headers = authenticationService.prepareRequest({});
    }

    onCloseTicket() {
        this.setState({ closing: true, errorCode: null });
    }

    async doCloseTicket() {
        this.setState({ closing: false, loading: true, errorCode: null });
        const ticket = {
            id: this.props.id,
            subject: null,
            description: null,
            theme: this.state.theme.id,
            status: 8,
            priority: this.state.priority.id,
            requestedBy: this.state.requestedBy,
            processedBy: this.state.processedBy,
            newComment: this.state.comment
        };
        await ticketService.updateTicket(ticket);
        this.setState({ loading: false, saved: true });
    }

    cancelCloseTicket() {
        this.setState({ closing: false, errorCode: null });
    }

    onDeleteAttachment(attachmentId: number) {
        const attachment = this.state.attachments.find((a: any) => a.id === attachmentId);
        this.setState({ attachmentToDelete: attachment });
    }

    cancelDeleteAttachment() {
        this.setState({ attachmentToDelete: undefined });
    }

    async doDeleteAttachment() {
        if (this.state.attachmentToDelete) {
            const attachmentId: number = this.state.attachmentToDelete.id;
            await ticketService.deleteAttachment(this.props.id, attachmentId);

            let attachments = this.state.attachments;
            const index = attachments.indexOf(this.state.attachmentToDelete);
            if (index >= 0) {
                attachments.splice(index, 1);
            }
            this.setState({ attachments: attachments, attachmentToDelete: undefined });
        }
    }

    render() {
        if (this.state.loading) {
            return (<Loader />);
        }
        else if (this.state.saved) {
            return (<Redirect to={'/tickets'} />);
        }
        else {
            const { t } = this.props;
            return (
                <div>
                    <Topbar />
                    <Tab.Container defaultActiveKey={'edit'}>
                        <EditTicketInfobar id={this.props.id} canSave={this.state.isValid && this.state.status.id !== 8} onSave={this.onSave} onCancel={this.onCancel} />
                        <div className="main-wrapper">
                            <div className="container">
                                <MetaContainer>
                                    <MetaField key={'id'} label={t('FranchiseEditTicketForm.metaId')} value={'#' + this.props.id} />
                                    <MetaField key={'requester'} label={t('FranchiseEditTicketForm.metaRequestedBy')} value={FranchiseService.formatUserName(this.state.requester)} />
                                    <MetaDateTimeField key={'creationTime'} label={t('FranchiseEditTicketForm.metaCreationTime')} value={this.state.creationTime} />
                                    <MetaDateTimeField key={'closedTime'} label={t('FranchiseEditTicketForm.metaEndTime')} value={this.state.closingTime} />
                                    {this.state.errorCode && (this.state.errorCode !== '') && (<div className={'alert alert-warning'}>{t('EditTicketError.' + this.state.errorCode)}
                                    </div>)}
                                </MetaContainer>
                                <Tab.Content >
                                    <Tab.Pane eventKey="edit">
                                        <ConfirmDialog title={t('FranchiseEditTicketForm.confirmDelete.title')}
                                            showDialog={this.state.attachmentToDelete !== undefined}
                                            onCancel={this.cancelDeleteAttachment} onConfirm={this.doDeleteAttachment}>
                                            <Trans i18nKey={'FranchiseEditTicketForm.confirmDelete.message'} values={{ filename: this.state.attachmentToDelete?.name }} />
                                        </ConfirmDialog>
                                        <ConfirmDialog title={t('FranchiseEditTicketForm.confirmClose.title')}
                                            showDialog={this.state.closing}
                                            onCancel={this.cancelCloseTicket} onConfirm={this.doCloseTicket}>
                                            <Trans i18nKey={'FranchiseEditTicketForm.confirmClose.message'} />
                                        </ConfirmDialog>
                                        {!this.state.attachmentToDelete && !this.state.closing && <Row>
                                            <Col md={6}>
                                                <Card>
                                                    <Card.Header>{t('FranchiseEditTicketForm.requestAttributes')}</Card.Header>
                                                    <Card.Body>
                                                        <div className="form-row">
                                                            <div className="form-group col-md-12">
                                                                <label>{t('FranchiseEditTicketForm.status')}</label>
                                                                <div className="input-group">
                                                                    <Input value={this.state.status.label} className={'form-control'} readOnly />
                                                                    {this.state.status.id !== 8 && 
                                                                    <div className="input-group-append">
                                                                        <button onClick={this.onCloseTicket} className={'btn btn-outline-primary btn-sm float-right'}>{t('Status.Close')}</button>
                                                                    </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label className={'col-form-label'}>{t('FranchiseEditTicketForm.theme')}</label>
                                                                <Input value={this.state.theme.label} className={'form-control'} readOnly />
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label className={'col-form-label'}>{t('FranchiseEditTicketForm.priority')}</label>
                                                                <Input value={this.state.priority.label} className={'form-control'} readOnly />
                                                                <span className="priority-description">{this.state.priority.description}</span>
                                                            </div>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                                <Card>
                                                    <Card.Body>
                                                        <div className="form-group">
                                                            <label htmlFor="subject">{t('FranchiseEditTicketForm.subject')}</label>
                                                            <input type="text" id="subject" className={'form-control'}
                                                                value={this.state.subject}
                                                                readOnly
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="description">{t('FranchiseEditTicketForm.description')}</label>
                                                            <textarea id="description" rows={10} className={'form-control'}
                                                                value={this.state.description}
                                                                readOnly
                                                            />
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                            <Col md={6}>
                                                <Card>
                                                    <Card.Header>{t('FranchiseEditTicketForm.comments')}</Card.Header>
                                                    <Card.Body>
                                                        <CommentList items={this.state.comments} />
                                                        <div className="form-group">
                                                            <label>{t('FranchiseEditTicketForm.newComment')}</label>
                                                            <textarea className="form-control" rows={5}
                                                                value={this.state.comment}
                                                                readOnly={this.state.status.id === 8}
                                                                onChange={(e) => { this.setState({ comment: e.target.value }); }} />
                                                        </div>
                                                        <div className="form-group">
                                                            <label>{t('FranchiseEditTicketForm.attachments')}</label>
                                                            <AttachmentList>
                                                                {
                                                                    this.state.attachments.map((a) => <Attachment canDelete={this.state.status.id !== 8}  key={a.id} id={a.id} onDelete={this.onDeleteAttachment} ticketId={this.props.id} contentType={a.contentType} fileName={a.name} size={a.size} />)
                                                                }
                                                            </AttachmentList>
                                                            <Upload
                                                                defaultFiles={[]} multiple={true} batch={false} autoUpload={false}
                                                                onBeforeUpload={this.onBeforeUpload}
                                                                onAdd={this.onUpdateFiles} onRemove={this.onUpdateFiles} onStatusChange={this.onUpdateFiles}
                                                                withCredentials={false}
                                                                saveUrl={ServiceUri + '/tickets/' + this.props.id + '/attachment'}
                                                                restrictions={{
                                                                    allowedExtensions: TicketService.AllowedAttachmentExtensions
                                                                }} />
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Row>
                                        }
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="history">
                                        <TimeLine data={this.state.timeline} />
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </div>
                    </Tab.Container >
                </div>
            );
        }
    }
}

export default withTranslation()(FranchiseEditTicketForm);
