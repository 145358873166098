import React, { Component } from 'react';
import Moment from 'react-moment';
import { withTranslation, WithTranslation } from 'react-i18next';

interface CommentProps extends WithTranslation {
    author: string;
    timestamp: Date;
    message: string;
}

class Comment extends Component<CommentProps, any> {
    render() {
        const { t } = this.props;
        return (
            <div className="comment">
                <div className="head">
                    <span className="author">{this.props.author}</span>
                    <Moment element="span" className="date" date={this.props.timestamp} format={t("moment.timestampFormat")} />
                </div>
                <span className="body">
                    <pre>{this.props.message}</pre>
                </span>
            </div>);
    }
}

export default withTranslation()(Comment);
