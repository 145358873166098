import React, { Component } from 'react';
import authenticationService from './services/AuthenticationService';
import { Link, Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import FontAwesomeIcon from './FontAwesome';
import { Dropdown } from 'react-bootstrap';

class Topbar extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.logout = this.logout.bind(this);
    }

    logout(e: any) {
        e.preventDefault();
        authenticationService.logout();
        this.setState({});
    }

    render() {
        const { t } = this.props;

        if (!authenticationService.isAuthenticated()) {
            return (<Redirect to={'/login'} />);
        }
        else {
            const me = authenticationService.me();
            return (
                <div className="topbar js-topbar">
                    <div className="container">
                        <Link className="logo" to="/tickets/"><img src="/img/picto-raison-home.png" alt="Raison Home" /></Link>
                        <div className="nav-menu">
                            <ul>
                                <li>
                                    <a>{t('infobar.title')}</a>
                                </li>
                            </ul>
                        </div>
                        <span className="ml-auto"></span>
                        {
                            (me.role === 'agent') && (<Link to={'/kpi'} title="Indicateurs" className="quicklink"><FontAwesomeIcon icon={'bar-chart'} size={'fw'} /></Link>)
                        }
                        <Link to="/tickets/" data-toggle="tooltip" data-placement="top" title={t('infobar.dashboard')} className="quicklink"><FontAwesomeIcon icon={'tachometer'} size={'fw'} /></Link>
                        <a href="https://cantine-cr.slack.com/" target={'_blank'} data-toggle="tooltip" data-placement="top" title="La cantine" className="quicklink"><FontAwesomeIcon icon={'slack'} size={'fw'} /></a>
                        <Dropdown>
                            <Dropdown.Toggle className={'auth-user'} id={'user'} as={'a'}>
                                <span className="thumb"><FontAwesomeIcon icon={'user'} /></span>
                                <span className="name">{authenticationService.me().displayName}</span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {(me.role === 'agent') && (<Dropdown.Item as={'div'}>
                                    <Link to={'/kpi'}>
                                        <span className="thumb"><FontAwesomeIcon icon={'bar-chart'} size={'fw'} className="mr-2" /></span>
                                        <span className="name">Indicateurs</span>
                                    </Link>
                                </Dropdown.Item>)}
                                <Dropdown.Item as={'div'}>
                                    <Link to={'/tickets'}>
                                        <span className="thumb"><FontAwesomeIcon icon={'tachometer'} size={'fw'} className="mr-2" /></span>
                                        <span className="name">Mes demandes</span>
                                    </Link>
                                </Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item as={'div'} onClick={this.logout}>
                                    <Link to={'/login'}>
                                        <span className="thumb"><FontAwesomeIcon icon={'sign-out'} size={'fw'} className="mr-2" /></span>
                                        <span className="name">{t('infobar.logout')}</span>
                                    </Link>
                                </Dropdown.Item>

                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            );
        }
    }
}

export default withTranslation()(Topbar);
