import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import FontAwesomeIcon from '../FontAwesome';

class ListInfobar extends Component<any,any> {
    render() {
        const { t } = this.props;
        return (
            <div className="infobar">
                <div className="container">
                    <div className="infos">
                        <span className="icon"><FontAwesomeIcon icon={'tachometer'} size={'fw'}/></span>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item active" aria-current="page">{t('listinfobar.dashboard')}</li>
                        </ol>
                    </div>
                    <div className="actions">
                        <Link className="btn btn-primary" to={'/ticket'}><FontAwesomeIcon icon={'plus'} size={'fw'}/><span className="d-none d-sm-inline-block"> {t('listinfobar.newTicket')}</span></Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(ListInfobar);