import React, { Component } from 'react';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import TicketStatus from '../TicketStatus';
import TicketPriority from '../TicketPriority';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import { withTranslation } from 'react-i18next';

import agentService, { AgentService } from '../../services/AgentService';
import themeService from '../../services/ThemeService';
import ticketService from '../../services/TicketService';
import Loader from '../../Loader';
import FontAwesomeIcon from '../../FontAwesome';

type FranchiseTicketGridState = {
    tickets: any | null,
    loading: boolean,
    skip: number
}

class FranchiseTicketGrid extends Component<any, FranchiseTicketGridState> {
    constructor(props: any) {
        super(props);
        this.state = {
            tickets: null,
            loading: false,
            skip: 0
        };
        this.pageChanged = this.pageChanged.bind(this);
        this.DateCell = this.DateCell.bind(this);
    }

    componentDidMount() {
        this.setState({ loading: true });
        this.populate();
    }

    private async populate() {
        const pagedResponse = await ticketService.getTickets();
        this.setState({ loading: false, tickets: pagedResponse });
    }


    async pageChanged(e: any) {
        this.setState({ loading: true });
        try {
            const pagedResponse = await ticketService.getTickets(e.page.skip, e.page.take);
            this.setState({ loading: false, tickets: pagedResponse, skip: e.page.skip + e.page.take - 1 });
        }
        catch
        {
            this.setState({ loading: false, tickets: null, skip: 0 });
        }
    }

    StatusCell(props: any) {
        return (<td className={'text-center'}><TicketStatus status={props.dataItem.status} /></td>);
    }

    PriorityCell(props: any) {
        return (<td className={'text-center'}><TicketPriority priority={props.dataItem.priority} /></td>);
    }

    EditCell(props: any) {
        return (<td className={'text-center'}><Link className="active" to={'/ticket/' + props.dataItem.id}><FontAwesomeIcon icon={'pencil'} size={'fw'} /></Link></td>);
    }

    DateCell(props: any) {
        const { t } = this.props;
        return (<td className={'text-center'}><Moment date={props.dataItem[props.field]} format={t("moment.dateFormat")} /></td>)
    }

    ThemeCell(props: any) {
        const def = themeService.get(props.dataItem.theme);
        return (<td className={'text-center'}>{def ? def.label : ''}</td>);
    }

    ProcessedByCell(props: any) {
        let agent = null;
        if (props.dataItem.processedBy) {
            agent = AgentService.formatUserName(agentService.findAgent(props.dataItem.processedBy));
        }
        return (<td>{agent}</td>);
    }

    render() {
        const { t } = this.props;

        if (this.state.loading) {
            return (<Loader />);
        }
        else if (this.state.tickets && this.state.tickets.items && this.state.tickets.items.length > 0) {
            return (
                <Grid className={'tickets-grid mb-4'} data={this.state.tickets.items} pageable={true} skip={this.state.skip} total={this.state.tickets.totalCount} pageSize={20} style={{ height: '100%' }} onPageChange={this.pageChanged}>
                    <GridColumn field="id" title={t("FranchiseTicketGrid.idColumn")} width={'70px'} sortable={true} />
                    <GridColumn field="priority" title={t("FranchiseTicketGrid.priorityColumn")} cell={this.PriorityCell} width={'70px'} />
                    <GridColumn field="subject" title={t("FranchiseTicketGrid.subjectColumn")} sortable={true} />
                    <GridColumn field="status" title={t("FranchiseTicketGrid.statusColumn")} cell={this.StatusCell} width={'140px'} />
                    <GridColumn field="processedBy" title={t("FranchiseTicketGrid.processedByColumn")} cell={this.ProcessedByCell} />
                    <GridColumn field="creationTime" title={t("FranchiseTicketGrid.creationTimeColumn")} cell={this.DateCell} width={'100px'} />
                    <GridColumn field="theme" title={t("FranchiseTicketGrid.themeColumn")} cell={this.ThemeCell} />
                    <GridColumn title={t("FranchiseTicketGrid.commandColumn")} cell={this.EditCell} width={'45px'} />
                </Grid>
            );
        }
        else if(this.state.tickets) {
            return (<div className={'container'}>{t("FranchiseTicketGrid.noTicket")}</div>);
        }
        else {
            return (<div className={'container'}>{t("FranchiseTicketGrid.internalError")}</div>);
        }

    }
}

export default withTranslation()(FranchiseTicketGrid);

