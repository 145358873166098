export const TicketStatusDefinitions = [
    {
        id: 1,
        class: 'state-label-new',
        key: 'new',
        label: 'Nouveau'
    },
    {
        id: 2,
        class: 'state-label-accepted',
        key: 'accepted',
        label: 'Accepté'
    },
    {
        id: 3,
        class: 'state-label-waiting-infos',
        key: 'waitingInternal',
        label: 'En attente de précisions par le franchisé'
    },
    {
        id: 4,
        class: 'state-label-waiting-elements',
        key: 'waitingExternal',
        label: 'En attente d\'éléments externes'
    },
    {
        id: 5,
        class: 'state-label-completed',
        key: 'completed',
        label: 'Terminé'
    },
    {
        id: 6,
        class: 'state-label-refused',
        key: 'refused',
        label: 'Refusé'
    },
    {
        id: 7,
        class: 'state-label-affected',
        key: 'affected',
        label: 'Affecté'
    },
    {
        id: 8,
        class: 'state-label-closed',
        key: 'closed',
        label: 'Fermé'
    }
];

export const TicketPriorityDefinitions = [
    {
        id: 1,
        class: 'icon-priority-critical',
        icon: 'fa fa-angle-double-up',
        key: 'critical',
        label: 'Bloquant',
        description : 'Demande prioritaire, vous êtes bloqué.e et vous n’avez aucune possibilité de contournement'
    },
    {
        id: 2,
        class: 'icon-priority-major',
        icon: 'fa fa-angle-up',
        key: 'major',
        label: 'Forte',
        description : 'Demande à traiter rapidement'
    },
    {
        id: 3,
        class: 'icon-priority-normal',
        icon: 'fa fa-minus',
        key: 'normal',
        label: 'Normal',
        description : 'Demande standard'
    },
    {
        id: 4,
        class: 'icon-priority-minor',
        icon: 'fa fa-angle-down',
        key: 'minor',
        label: 'Faible',
        description : 'Demande de faible importance'
    }
];

export function internationalize(constants : any[], ns: string, t : Function, field: string = 'label') {
    constants.forEach((c : any) => {c[field] = t(ns + '.' + c.key) });
}

