import React, { Component, Fragment } from 'react';
import { Card, Form } from 'react-bootstrap';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import Loader from './Loader';
import { withTranslation, WithTranslation } from 'react-i18next';
import authenticationService from './services/AuthenticationService';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { ServiceUri } from './services/ServicesUri';
import FontAwesomeIcon from './FontAwesome';

type LoginFormState = {
    username: string,
    password: string,
    error: string | null,
    loading: boolean,
    returnUrl: string,
    lang: any
}

type TextInputFormGroupProps = {
    label: string,
    required?: boolean,
    id: string,
    autoComplete?: string,
    value: string,
    placeholder?: string,
    maxLength?: number,
    minLength?: number,
    type?: 'email' | 'password' | 'text',
    onChange: Function
}

type TextInputFormGroupState = {
    invalid: boolean
}

class TextInputFormGroup extends Component<TextInputFormGroupProps, TextInputFormGroupState> {
    constructor(props: any) {
        super(props);
        this.state = {
            invalid: this.props.required ? this.props.value === '' : false
        }
        this.onValueChange = this.onValueChange.bind(this);
    }

    onValueChange(e: any) {
        if (this.props.required) {
            this.setState({ invalid: !e.target.value || (e.target.value === '') });
        }
        if (this.props.onChange) {
            this.props.onChange(e.target.value);
        }
    }

    render() {
        const className = 'form-control' + (this.state.invalid ? ' is-invalid' : '');
        return (
            <div className="form-group">
                <label htmlFor={this.props.id}>{this.props.label}</label>
                <input className={className}
                    required
                    id={this.props.id}
                    value={this.props.value}
                    onChange={this.onValueChange}
                    placeholder={this.props.placeholder}
                    type={this.props.type || 'text'}
                    maxLength={this.props.maxLength}
                    minLength={this.props.minLength}
                    autoComplete={this.props.autoComplete} />
            </div>);
    }
}


class LoginForm extends Component<RouteComponentProps & WithTranslation, LoginFormState> {

    static readonly languages = [
        {
            id: 'fr',
            label: 'Français'
        },
        {
            id: 'en',
            label: 'English'
        }
    ];

    constructor(props: any) {
        super(props);
        this.onLogin = this.onLogin.bind(this);
        this.changeLanguage = this.changeLanguage.bind(this);
        const { i18n } = this.props;
        const currentLanguage = i18n.languages[0];
        const lang = currentLanguage ? LoginForm.languages.find((l) => l.id === currentLanguage) : null;
        const { location } = this.props;
        let errorCode = null;
        let returnUrl = '/tickets/';
        if (location.search && location.search !== '') {
            const searchParams = new URLSearchParams(location.search);
            if (searchParams.has('q')) {
                returnUrl = decodeURIComponent(searchParams.get('q') || '');
            }
            if (searchParams.has('error')) {
                errorCode = decodeURIComponent(searchParams.get('error') || '');
            }
            if (searchParams.has('token')) {
                const token = decodeURIComponent(searchParams.get('token') || '');
                authenticationService.auth(token);
            }
        }
        this.state = {
            username: '',
            password: '',
            error: errorCode,
            lang: lang,
            returnUrl: returnUrl,
            loading: false
        };
    }

    changeLanguage(e: any) {
        const { i18n } = this.props;
        i18n.changeLanguage(e.target.value.id);
        this.setState({ lang: e.target.value });
    }

    async componentDidMount() {
        document.body.className = "fullscreen-layout page-login";
    }

    async onLogin(event: any) {
        const form = event.target;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            return;
        }

        event.preventDefault();
        this.setState({ loading: true });

        try {
            await authenticationService.login(this.state.username, this.state.password, this.state.lang?.id);
            if (authenticationService.isAuthenticated()) {
                this.setState({ error: null, password: '', loading: false });
            }
            else {
                this.setState({ error: "invalid_credentials", password: '', loading: false });
            }
        }
        catch (exception) {
            this.setState({ error: "internal_error", password: '', loading: false });
        }
    }

    FlagRender(element: any, flag: any) {
        if (!flag) return element;
        const children =
            <span key={1}><img alt={flag.label} style={{ width: '28px', height: '20px' }} src={'./img/flag-' + flag.id + '.png'} className="icon-flag" /> {flag.label}</span>;
        return React.cloneElement(element, { ...element.props }, children);
    }

    ItemRender(li: any, itemsProps: any) {
        const itemChildren = <span><img alt={itemsProps.dataItem.label} style={{ width: '28px', height: '20px' }} src={'./img/flag-' + itemsProps.dataItem.id + '.png'} className="icon-flag" /> {itemsProps.dataItem.label}</span>;
        return React.cloneElement(li, li.props, itemChildren);
    }

    getExternalLoginUri(provider: string, lang: string, returnUrl?: string) {
        let uri = ServiceUri + '/login/external/' + provider + '/' + lang;
        if (returnUrl) {
            uri = uri + '?q=' + returnUrl;
        }
        return uri;
    }

    canLogin() {
        return (this.state.username !== null && this.state.username !== '') && (this.state.password !== null && this.state.password !== '');
    }

    render() {
        const { t } = this.props;
        if (this.state.loading) {
            return (<Loader />);
        }
        else if (authenticationService.isAuthenticated()) {
            return (<Redirect to={this.state.returnUrl} />);
        }
        else {
            return (
                <Fragment>
                    <div className="locale-dropdown">
                        <DropDownList data={LoginForm.languages} defaultValue={LoginForm.languages[0]} value={this.state.lang} itemRender={this.ItemRender} valueRender={this.FlagRender} dataItemKey={'id'} onChange={this.changeLanguage} />
                    </div>
                    <div className="container">
                        <div className="logo"><img src="./img/logo-raison-home.png" alt="Raison Home" /></div>
                        <Form noValidate={false} onSubmit={this.onLogin} validated={true}>
                            <Card>
                                <Card.Header>{t('login.franchiseOnly')}</Card.Header>
                                <Card.Body>
                                    <div className="actions text-right">
                                        <a className="btn btn-social" style={{ backgroundColor: '#4285F4', color: 'white' }} href={this.getExternalLoginUri('Google', this.state.lang?.id || 'fr', this.state.returnUrl)} target="_self"><FontAwesomeIcon icon={'google'} />{t('login.googleAuth')}</a>
                                    </div>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Header>{t('login.prompt')}</Card.Header>
                                <Card.Body>
                                    <TextInputFormGroup label={t('login.username')} placeholder={t('login.username-placeholder')} id="username" value={this.state.username}
                                        type="email"
                                        required
                                        onChange={(u: any) => { this.setState({ username: u }) }} />
                                    <TextInputFormGroup label={t('login.password')} placeholder={t('login.password-placeholder')} id="password" value={this.state.password}
                                        type="password" autoComplete={'current-password'}
                                        required
                                        onChange={(u: any) => { this.setState({ password: u }) }} />
                                    {this.state.error &&
                                        <div className={'alert alert-warning'}> {t('login.' + this.state.error)}
                                        </div>
                                    }
                                    <div className="form-group m-0 text-right">
                                        <button type="submit" className="btn btn-primary" disabled={!this.canLogin()} >{t('login.submit')}</button>
                                    </div>
                                    <hr />
                                </Card.Body>
                            </Card>
                        </Form>
                    </div>
                </Fragment>
            );
        }
    }
}

export default withTranslation()(LoginForm);