import React, { Component } from 'react';
type StatCardProps = {
    variant?: String,
    style?: React.CSSProperties | undefined
}

export class StatCard extends Component<StatCardProps, any> {
    render() {
        const className ='stat-card' + (this.props.variant ? ' stat-card-'+ this.props.variant : '');
        return (
            <div className={className} style={this.props.style}>
                {this.props.children}
            </div>
        );
    }
}

export class DoubleStatCard extends Component<StatCardProps, any> {
    render() {
        const className ='stat-card stat-card-double' + (this.props.variant ? ' stat-card-'+ this.props.variant : '');
        return (
            <div className={className}>
                {this.props.children}
            </div>
        );    
    }
}
