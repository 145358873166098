import authenticationService from './AuthenticationService';
import jsonService from './jsonService';

interface SearchView {
    offset: number,
    pageSize: number,
    criteria: any | null
}
export class TicketService {
    static AllowedAttachmentExtensions = ['.jpg', '.jpeg', '.png', '.docx', '.doc', '.xlsx', '.xls', '.pdf'];

    async getStatistics() {
        const response = await jsonService.get('/tickets/statistic', authenticationService.prepareRequest({}));
        const data: any = await response.json();
        return data;
    }

    async getTickets(offset?: number, pageSize?: number, searchCritera?: any) {
        offset = offset ? offset : 0;
        pageSize = pageSize ? pageSize : 20;
        let view: SearchView = {
            offset: offset,
            pageSize: pageSize,
            criteria: null
        };
        if (searchCritera) {
            if (searchCritera.ticketId && searchCritera.ticketId > 0) {
                view.criteria = {};
                view.criteria.ticketId = searchCritera.ticketId;
            }
            else if (searchCritera.text !== '' || searchCritera.priorities.length > 0 || searchCritera.themes.length > 0 || searchCritera.status.length > 0 || searchCritera.processedBy || searchCritera.requestedBy || searchCritera.from || searchCritera.until) {
                view.criteria = {};
                view.criteria.text = searchCritera.text;
                view.criteria.priorities = searchCritera.priorities;
                view.criteria.status = searchCritera.status;
                view.criteria.processedBy = searchCritera.processedBy;
                view.criteria.requestedBy = searchCritera.requestedBy;
                view.criteria.themes = searchCritera.themes;
                view.criteria.from = searchCritera.from;
                view.criteria.until = searchCritera.until;
            }
        }

        const response = await jsonService.post('/tickets/all', authenticationService.prepareRequest({}), view);
        const data: any = await response.json();
        return data;
    }

    async addTicket(ticket: any) {
        const url = '/tickets/';
        ticket.id = 0;
        ticket.priority = parseInt(ticket.priority);
        ticket.theme = parseInt(ticket.theme);

        ticket.requestedBy = parseInt(ticket.requestedBy);
        if ((ticket.processedBy === null) || (ticket.processedBy === '')) {
            ticket.processedBy = null;
        }
        ticket.processedBy = parseInt(ticket.processedBy);
        const response = await jsonService.post(url, authenticationService.prepareRequest({}), ticket);
        return await response.json();
    }

    async addAttachment(id: number, file: any) {
        const url = '/tickets/' + id + '/attachment';
        const response = await jsonService.upload(url, file, authenticationService.prepareRequest({}));
        return await response.json();
   }

    async deleteAttachment(ticketId: number, attachmentId: number) {
        const url = '/tickets/' + ticketId + '/attachment/' + attachmentId;
        await jsonService.delete(url, authenticationService.prepareRequest({}));
    }

    async getAttachment(ticketId: number, attachmentId: number) {
        const url = '/tickets/' + ticketId + '/attachment/' + attachmentId;
        const response = await jsonService.get(url, authenticationService.prepareRequest({}));
        return await response.blob();
    }

    async getImage(ticketId: number, attachmentId: number, size?: 0 | 1)
    {
        const url = '/tickets/' + ticketId + '/image/' + attachmentId + (size === undefined ? '' : '?size='+size);
        
        const response = await jsonService.get(url, authenticationService.prepareRequest({}));
        return await response.blob();
    }

    async updateTicket(ticket: any) {
        const url = '/tickets/';
        ticket.priority = parseInt(ticket.priority);
        ticket.theme = parseInt(ticket.theme);
        ticket.status = parseInt(ticket.status);
        ticket.requestedBy = parseInt(ticket.requestedBy);
        if ((ticket.processedBy === null) || (ticket.processedBy === '')) {
            ticket.processedBy = null;
        }
        ticket.processedBy = parseInt(ticket.processedBy);

        const response = await jsonService.put(url, authenticationService.prepareRequest({}), ticket);
        await response.json();
    }

    async getTicket(id: number) {
        try {
            const response = await jsonService.get('/tickets/' + id, authenticationService.prepareRequest({}));
            if (response.status === 200) {
                const data = await response.json();
                return data;
            }
        }
        catch {
        }
        return null;
    }
}

const ticketService = new TicketService();

export default ticketService;