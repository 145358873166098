import StringUtils from './StringUtils';

interface ValidationResult {
    valid: boolean,
    error: string | null,
    parameters : any
}

export class TicketValidationService {
    static Success : ValidationResult = {valid: true, error: null, parameters:null};

    isValidSubject(text: string | null): ValidationResult {
        let result = this.required(text);
        if(result.valid) {
            result = this.minLen(text ?? '', 5);
        }
        if(result.valid) {
            result = this.maxLen(text ?? '', 250);
        }
        return result;
    }

    isValidTheme(id : number | null) : ValidationResult
    {
        if(id !== null && id > 0)
        {
            return TicketValidationService.Success;
        }
        else
        {
            return this.error('mandatoryField');
        }
    }

    isValidDescription(text: string | null): ValidationResult {
        let result = this.required(text);
        if(result.valid) {
            result = this.minLen(text ?? '', 10);
        }
        return result;
    }

    isValidRequester(userId: number | null): ValidationResult {
        if ((userId === null) || (userId === 0)) {
            return this.error('mandatoryField');
        }
        return TicketValidationService.Success;
    }

    format(t: Function, prefix : string, result: ValidationResult) : string | null {
        if(result.valid) {
            return null;
        }
        return t(prefix + '.' + result.error, result.parameters);
    }

    private required(text: string | null) : ValidationResult {
        if(text === null || StringUtils.isWhiteSpace(text)) {
            return this.error('mandatoryField');
        }        
        return TicketValidationService.Success;
    }

    private error(code : string, parameters?: any) : ValidationResult {
        return { valid: false, error: code, parameters:parameters };
    }

    private minLen(text: string, len : number) : ValidationResult {
        if(text.length < len) {
            return this.error('tooShortString', {len: len} );
        }
        return TicketValidationService.Success;
    }

    private maxLen(text: string, len : number) : ValidationResult {
        if(text.length > len) {
            return this.error('tooLongString', {len: len} );
        }
        return TicketValidationService.Success;
    }    
}

const validationService = new TicketValidationService();
export default validationService;