import React, { Component } from 'react';
import Chart from 'chart.js';

// export type DataSet = {
//     title: string,
//     values: any[],
//     color: string
// }

type BarChartProps = {
    variant?: 'bar' | 'line',
    className?: string,
    labels: string[],
    data: any[]
}

export default class BarChart extends Component<BarChartProps> {

    chartRef: any = null;
    myChart: any = null;

    constructor(props: any) {
        super(props);
        this.chartRef = React.createRef();
    }

    componentDidUpdate(newProps : any) {
        this.myChart.data.datasets = this.props.data.map((dataset: any) => { return { label: dataset.title, backgroundColor: dataset.color, data: dataset.values }; })
        this.myChart.update();
    }

    componentDidMount() {
        this.myChart = new Chart(this.chartRef.current, {
            type: this.props.variant || 'bar',
            options: {
                legend: {
                    onClick: (e) => e.stopPropagation()
                },
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                min: 0
                            }
                        }
                    ]
                }
            },
            data: {
                labels: this.props.labels,
                datasets: this.props.data.map((dataset: any) => { return { label: dataset.title, backgroundColor: dataset.color, data: dataset.values, fill:false, borderColor:dataset.color }; })
            }
        });
    }

    render() {
        return (
            <canvas className={this.props.className} ref={this.chartRef} />
        );
    }
}