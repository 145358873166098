import { Component } from "react";
import Topbar from './Topbar';
import { Tab, Nav } from 'react-bootstrap';
import React from "react";
import FontAwesomeIcon from "./FontAwesome";
import RequestPerMonth from './RequestPerMonth';
import PriseEnCharge from './PriseEnCharge';
import { withTranslation, WithTranslation } from "react-i18next";
import { IntlProvider, LocalizationProvider, registerForIntl } from "@progress/kendo-react-intl";
import i18n from "./i18n";
import { Redirect } from "react-router";
import authenticationService from "./services/AuthenticationService";

export class PkiPage extends Component<any & WithTranslation> {
    constructor(props: any) {
        super(props);
    }
    
    render() {
        const {t} = this.props;
        const me = authenticationService.me();
        if (!authenticationService.isAuthenticated()) {
            return (<Redirect to={'/login'} />);
        }
        else if (me.role === 'franchise') {
            return (<Redirect to={'/tickets'} />);
        }
        else {
        return (
            <LocalizationProvider language={i18n.language}>
                <IntlProvider locale={i18n.language}>
                    <div>
                        <Topbar />
                        <Tab.Container defaultActiveKey={'requestsPerMonth'}>
                        <div className="infobar">
                            <div className="container">
                                <div className="infos">
                                    <span className="icon"><FontAwesomeIcon icon={'bar-chart'} size={'fw'}/></span>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item active" aria-current="page">{t('pkiForm.title')}</li>
                                    </ol>
                                </div>
                                <Nav as="ul">
                                    <Nav.Item as="li">
                                        <Nav.Link eventKey="requestsPerMonth">{t('pkiForm.requestsPerMonth')}</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item as="li">
                                        <Nav.Link eventKey="priseEnCharge">{t('priseEnCharge.name')}</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                        </div>
                        <Tab.Content >
                            <Tab.Pane eventKey="requestsPerMonth">
                                <RequestPerMonth></RequestPerMonth>
                            </Tab.Pane>
                            <Tab.Pane eventKey="priseEnCharge">
                                <PriseEnCharge></PriseEnCharge>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>
            </IntlProvider>
        </LocalizationProvider>);
        }
    }
}

registerForIntl(PkiPage);

export default withTranslation()(PkiPage);
