import React, { Component } from 'react';
import { Upload, UploadFileStatus } from '@progress/kendo-react-upload';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Redirect } from 'react-router';
import { Tab, Row, Col, Card } from 'react-bootstrap';
import ThemeList from '../ThemeList';

import ConfirmDialog from '../ConfirmDialog';

import { MetaContainer, MetaField } from '../MetaDataItems';
import MetaDateTimeField from '../MetaDateTimeField';

import Topbar from '../../Topbar';
import EditTicketInfobar from '../EditTicketInfobar';
import AgentList from '../../AgentList';
import FranchiseList from '../../FranchiseList';
import CommentList from '../CommentList';
import TimeLine from '../TimeLine';
import Attachment from '../Attachment';
import AttachmentList from '../AttachmentList';

import { TicketStatusDefinitions, TicketPriorityDefinitions, internationalize } from '../../Enums'
import themeService from '../../services/ThemeService';
import StringUtils from '../../services/StringUtils';
import ticketService, { TicketService } from '../../services/TicketService';
import franchiseService, { FranchiseService } from '../../services/FranchiseService';
import Loader from '../../Loader';
import { ServiceUri } from '../../services/ServicesUri';
import authenticationService from '../../services/AuthenticationService';
import { withTranslation, WithTranslation, Trans } from 'react-i18next';
import agentService from '../../services/AgentService';
import validationService from '../../services/TicketValidationService';

type AgentEditTicketFormProps = {
    id: number
}

type AgentEditTicketFormState = {
    loading: boolean,
    isValid: boolean,
    subject: string,
    description: string,
    creationTime: Date | null,
    closingTime: Date | null,
    theme: any,
    status: any,
    originalStatus: any,
    priority: any,
    requestedBy: number,
    processedBy: number | null,
    comments: Array<any>
    comment: string,
    requester: any,
    timeline: any,
    errorCode: string | null,
    attachmentToDelete: any | undefined,
    attachments: Array<any>,
    files : Array<any>,
    saved: boolean,
    errors: {
        subject: string | null,
        requestedBy: string | null,
        theme : string | null,
    }
}

class AgentEditTicketForm extends Component<AgentEditTicketFormProps & WithTranslation, AgentEditTicketFormState> {
    constructor(props: any) {
        super(props);
        const { t } = this.props;
        internationalize(TicketPriorityDefinitions, 'Priority.label', t);
        internationalize(TicketPriorityDefinitions, 'Priority.description', t, 'description');
        internationalize(TicketStatusDefinitions, 'Status', t);
        this.state = {
            loading: true,
            creationTime: null,
            isValid: true,
            saved: false,
            subject: '',
            description: '',
            closingTime: null,
            theme: null,
            status: null,
            originalStatus: null,
            priority: null,
            requestedBy: 0,
            processedBy: null,
            requester: null,
            timeline: null,
            comment: '',
            attachmentToDelete: undefined,
            attachments: [],
            files: [],
            comments: [],
            errorCode: null,
            errors:
            {
                subject: null,
                requestedBy: null,
                theme : null
            }
        }
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onUpdateFiles = this.onUpdateFiles.bind(this);
        this.onDeleteAttachment = this.onDeleteAttachment.bind(this);
        this.doDeleteAttachment = this.doDeleteAttachment.bind(this);
        this.doShowImage = this.doShowImage.bind(this);
        this.cancelDeleteAttachment = this.cancelDeleteAttachment.bind(this);
        this.affectMe = this.affectMe.bind(this);
        this.changeAgent = this.changeAgent.bind(this);
        this.changeStatus = this.changeStatus.bind(this);
    }

    async componentDidMount() {
        const ticket = await ticketService.getTicket(this.props.id);
        if (ticket == null) {
            this.setState({
                loading: false,
                saved: true
            });
        }
        else {
            const {t} = this.props;
            await themeService.getThemes();
            await franchiseService.getFranchises();
            const theme = themeService.get(0);
            this.setState({
                loading: false,
                subject: ticket.subject,
                creationTime: ticket.creationTime,
                closingTime: ticket.closingTime,
                comments: ticket.comments || [],
                attachments: ticket.attachments || [],
                description: ticket.description,
                theme: themeService.get(ticket.theme),
                priority: TicketPriorityDefinitions.find((item) => item.id === ticket.priority),
                status: TicketStatusDefinitions.find((item) => item.id === ticket.status),
                originalStatus: ticket.status,
                requestedBy: ticket.requestedBy,
                processedBy: ticket.processedBy,
                timeline: ticket.timeline,
                requester: franchiseService.findFranchise(ticket.requestedBy)
            }, this.validate);
        }
    }

    onCancel() {
        var files = this.state.files;
        if(files.some((file) => file.status == UploadFileStatus.Uploading))
        {
            return;
        }
        this.setState({ loading: false, saved: true });
    }

    async onSave() {
        if (!authenticationService.isAuthenticated()) {
            this.setState({ loading: false, saved: true });
            return;
        }

        if (this.state.errors.subject !== null) {
            this.setState({ loading: false });
            return;
        }

        this.setState({ loading: true, errorCode: null });
        const ticket = {
            id: this.props.id,
            subject: this.state.subject,
            description: null,
            theme: this.state.theme.id,
            status: this.state.status.id,
            priority: this.state.priority.id,
            requestedBy: this.state.requestedBy,
            processedBy: this.state.processedBy,
            newComment: StringUtils.isWhiteSpace(this.state.comment) ? null : this.state.comment
        };
        try {
            await ticketService.updateTicket(ticket);
            var hasFilesToUpload = this.state.files.length !== 0 && this.state.files.some(file => file.status !== UploadFileStatus.Uploaded);
            if(hasFilesToUpload)
            {
                const files = this.state.files;
                files.forEach((file : any, index: number, array: any[]) => {
                    if(file.status !== UploadFileStatus.Uploading && file.status !== UploadFileStatus.Uploaded)
                    {
                        file.status = UploadFileStatus.Uploading;
                        this.setState({ files : array});
                        ticketService.addAttachment(this.props.id, file.getRawFile()).then(() => 
                        {
                            file.status = UploadFileStatus.Uploaded;
                            var allfilesUploaded = array.every(value => value.status === UploadFileStatus.Uploaded);
                            this.setState({ files : array, saved : allfilesUploaded });
                        });
                    }
                });
            }
            this.setState({ saved: ! hasFilesToUpload, loading: false });
        }
        catch {
            this.setState({ saved: false, loading: false, errorCode: 'internalError' });
        }
    }

    onUpdateFiles(e: any) {
        this.setState({ files: e.newState });
    }

    onBeforeUpload(e: any) {
        e.headers = authenticationService.prepareRequest({});
    }

    onDeleteAttachment(attachmentId: number) {
        const attachment = this.state.attachments.find((a: any) => a.id === attachmentId);
        this.setState({ attachmentToDelete: attachment });
    }

    cancelDeleteAttachment() {
        this.setState({ attachmentToDelete: undefined });
    }

    affectMe(e: any) {
        e.preventDefault();
        this.changeAgent(authenticationService.me());
    }

    setRequester(user: any) {
        this.setState({ requestedBy: user?.id }, this.validate);
    }

    changeAgent(user: any) {
        let status = this.state.status;
        if(! user)
        {
            status = TicketStatusDefinitions.find((item) => item.id === 1);
        }
        else
        {
            if(this.state.processedBy === null)
            {
                status = TicketStatusDefinitions.find((item) => item.id === 7);
            }    
        }
        this.setState({ processedBy: user?.id, status: status });
    }

    changeStatus(current : any)
    {
        var changes :any = {};
        if(current.id === 1)
        {
            if(this.state.processedBy !== null)
            {
                changes['processedBy'] = null;
            }
        }
        changes['status'] = current;
        this.setState(changes);
    }

    setSubject(value: string) {
        this.setState({ subject: value }, this.validate);
    }

    async doShowImage(e: any) {

    }

    validate() {
        const { t } = this.props;
        const subjectError = validationService.format(t, 'Error', validationService.isValidSubject(this.state.subject));
        const requestedByError = validationService.format(t, 'Error',validationService.isValidRequester(this.state.requestedBy));
        const themeError = validationService.format(t, 'Error', validationService.isValidTheme(this.state.theme?.id));
        this.setState({ isValid: (subjectError === null && requestedByError === null && themeError === null && this.state.requestedBy !== null), errors: { subject: subjectError, requestedBy: requestedByError, theme : themeError } });
    }

    async doDeleteAttachment() {
        if (this.state.attachmentToDelete) {
            const attachmentId: number = this.state.attachmentToDelete.id;
            await ticketService.deleteAttachment(this.props.id, attachmentId);

            let attachments = this.state.attachments;
            const index = attachments.indexOf(this.state.attachmentToDelete);
            if (index >= 0) {
                attachments.splice(index, 1);
            }
            this.setState({ attachments: attachments, attachmentToDelete: undefined });
        }
    }

    render() {
        const { t } = this.props;
        if (this.state.loading) {
            return (<Loader />);
        }
        else if (this.state.saved) {
            return (<Redirect to={'/tickets'} />);
        }
        else {
            return (
                <div>
                    <Topbar />
                    <Tab.Container defaultActiveKey={'edit'}>
                        <EditTicketInfobar id={this.props.id} canSave={this.state.isValid && this.state.originalStatus !== 8} onSave={this.onSave} onCancel={this.onCancel} />
                        <div className="main-wrapper">
                            <div className="container">
                                <MetaContainer>
                                    <MetaField key={'id'} label={t('AgentEditTicketForm.metaId')} value={'#' + this.props.id} />
                                    <MetaField key={'requester'} label={t('AgentEditTicketForm.metaRequestedBy')} value={FranchiseService.formatUserName(this.state.requester)} />
                                    <MetaDateTimeField key={'creationTime'} label={t('AgentEditTicketForm.metaCreationTime')} value={this.state.creationTime} />
                                    <MetaDateTimeField key={'closedTime'} label={t('AgentEditTicketForm.metaEndTime')} value={this.state.closingTime} />
                                    {this.state.errorCode && (this.state.errorCode !== '') && (<div className={'alert alert-warning'}>{t('EditTicketError.' + this.state.errorCode)}
                                    </div>)}
                                </MetaContainer>
                                <Tab.Content >
                                    <Tab.Pane eventKey="edit">
                                        <ConfirmDialog title={t('AgentEditTicketForm.confirmDelete.title')}
                                            showDialog={this.state.attachmentToDelete !== undefined}
                                            onCancel={this.cancelDeleteAttachment} onConfirm={this.doDeleteAttachment}>
                                            <Trans i18nKey={'AgentEditTicketForm.confirmDelete.message'} values={{ filename: this.state.attachmentToDelete?.name }} />
                                        </ConfirmDialog>
                                        {!this.state.attachmentToDelete &&
                                            <Row>
                                                <Col md={6}>
                                                    <Card>
                                                        <Card.Header>{t('AgentEditTicketForm.requestAttributes')}</Card.Header>
                                                        <Card.Body>
                                                            <div className="form-row">
                                                                <div className="form-group col-md-6">
                                                                    <label>{t('AgentEditTicketForm.requestedBy')}</label>
                                                                    <FranchiseList 
                                                                        userId={this.state.requestedBy} 
                                                                        required={true}
                                                                        disabled={this.state.originalStatus === 8}
                                                                        onChange={(user: any) => { this.setRequester(user); }} />
                                                                </div>
                                                                <div className="form-group col-md-6">
                                                                    <label>{t('AgentEditTicketForm.processedBy')}</label>{this.state.originalStatus !== 8 && <a className={'btn btn-primary btn-sm float-right'} href="/me"
        onClick={this.affectMe}>{t('AgentEditTicketForm.me')}</a> }
                                                                    <AgentList 
                                                                        userId={this.state.processedBy} 
                                                                        required={false}
                                                                        disabled={this.state.originalStatus === 8}
                                                                        onChange={this.changeAgent} />
                                                                </div>
                                                                <div className="form-group col-md-6">
                                                                    <label>{t('AgentEditTicketForm.status')}</label>
                                                                    <DropDownList className={'form-control'} data={agentService.getStates(this.state.originalStatus)} textField={'label'} dataItemKey={'id'}
                                                                        value={this.state.status}
                                                                        onChange={(e: any) => {
                                                                            this.changeStatus(e.target.value);
                                                                        }} />
                                                                </div>
                                                                <div className="form-group col-md-6">
                                                                    <label>{t('AgentEditTicketForm.theme')}</label>
                                                                    <ThemeList 
                                                                        error={this.state.errors.theme}
                                                                        value={this.state.theme} 
                                                                        onChange={(value: any) => { 
                                                                            this.setState({ theme: value }, this.validate);
                                                                        }} />
                                                                </div>
                                                                <div className="form-group col-md-6">
                                                                    <label>{t('AgentEditTicketForm.priority')}</label>
                                                                    <DropDownList className={'form-control'} data={TicketPriorityDefinitions} textField={'label'} dataItemKey={'id'}
                                                                        value={this.state.priority}
                                                                        onChange={(e: any) => {
                                                                            this.setState({ priority: e.target.value });
                                                                        }} />
                                                                    <span className="priority-description">{this.state.priority.description}</span>
                                                                </div>
                                                            </div>
                                                        </Card.Body>
                                                    </Card>
                                                    <Card>
                                                        <Card.Body>
                                                            <div className="form-group">
                                                                <label htmlFor="subject">{t('AgentEditTicketForm.subject')}</label>
                                                                <input type="text" id="subject" className={'form-control' + (this.state.errors.subject === null ? '' : ' is-invalid')}
                                                                    value={this.state.subject}
                                                                    readOnly={this.state.originalStatus === 8}
                                                                    onChange={(e: any) => { this.setSubject(e.target.value); }} />
                                                                <div className="invalid-feedback">{this.state.errors.subject}</div>
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="description">{t('AgentEditTicketForm.description')}</label>
                                                                <textarea id="description" rows={10} className={'form-control'}
                                                                    readOnly
                                                                    value={this.state.description}
                                                                />
                                                            </div>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                                <Col md={6}>
                                                    <Card>
                                                        <Card.Header>{t('AgentEditTicketForm.comments')}</Card.Header>
                                                        <Card.Body>
                                                            <CommentList items={this.state.comments} />
                                                            <div className="form-group">
                                                                <label>{t('AgentEditTicketForm.newComment')}</label>
                                                                <textarea className="form-control" rows={5}
                                                                    value={this.state.comment}
                                                                    readOnly={this.state.originalStatus === 8}
                                                                    onChange={(e) => { this.setState({ comment: e.target.value }); }} />
                                                            </div>
                                                            <div className="form-group">
                                                                <label>{t('AgentEditTicketForm.attachments')}</label>
                                                                <AttachmentList>
                                                                    {
                                                                        this.state.attachments.map((a) => <Attachment  canDelete={this.state.originalStatus !== 8} key={a.id} id={a.id} onDelete={this.onDeleteAttachment} onDisplay={this.doShowImage} ticketId={this.props.id} contentType={a.contentType} fileName={a.name} size={a.size} />)
                                                                    }
                                                                </AttachmentList>
                                                                <Upload defaultFiles={[]} multiple={true} batch={false} autoUpload={false}
                                                                    onBeforeUpload={this.onBeforeUpload}
                                                                    onAdd={this.onUpdateFiles} onRemove={this.onUpdateFiles} onStatusChange={this.onUpdateFiles}
                                                                    disabled={this.state.originalStatus === 8}
                                                                    restrictions={{
                                                                        allowedExtensions: TicketService.AllowedAttachmentExtensions
                                                                    }}
                                                                    withCredentials={false}
                                                                    saveUrl={ServiceUri + '/tickets/' + this.props.id + '/attachment'} />
                                                            </div>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        }
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="history">
                                        <TimeLine data={this.state.timeline} />
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </div>
                    </Tab.Container >
                </div>);
        }
    }
}

export default withTranslation()(AgentEditTicketForm);
