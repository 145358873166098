import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { HashRouter } from 'react-router-dom';
import { I18nextProvider } from "react-i18next";

import i18n from './components/i18n';
import * as Sentry from '@sentry/browser';

require('./scss/main.scss');
require('bootstrap');
require('bedrock');

const $ = require("jquery");

const element = document.getElementsByTagName('base')[0];
let baseUrl: string | undefined = undefined;
if (element) {
    const hrefAttribute = element.getAttribute('href');
    if (hrefAttribute) {
        baseUrl = hrefAttribute;
    }
}

if (process.env.NODE_ENV && process.env.NODE_ENV !== 'development') {
    Sentry.init({ dsn: "http://209629dacf11446e9a9174ca7d7dffe2@i6tml01.6tm.com:9000/9" });
}

ReactDOM.render(<I18nextProvider i18n={i18n}><HashRouter basename={baseUrl}><App /></HashRouter></I18nextProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
