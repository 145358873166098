import React, { Component } from 'react';
import FontAwesomeIcon from '../FontAwesome';
import ticketService from '../services/TicketService';
import {Lightbox} from 'react-modal-image';
import authenticationService from '../services/AuthenticationService';

type AttachmentProps = {
    ticketId: number,
    fileName: string,
    canDelete: boolean,
    id: number,
    contentType?: string,
    onDelete?: Function,
    onDisplay?: Function,
    size: number
}

type AttachmentState = {
    downloading: boolean;
    isImage: boolean;
    openLightBox: boolean;
    image: { small:string, large: string};
}

export default class Attachment extends Component<AttachmentProps, AttachmentState> {
    static formatSize(size: number) {
        if (size < 1024) {
            return size + ' B';
        }
        else {
            size = size / 1024;
            if (size < 1024) {
                return (Math.round(size * 100) / 100) + ' KB';
            }
            else {
                size = size / 1024;
                return (Math.round(size * 100) / 100) + ' MB';
            }
        }
    }

    constructor(props: any) {
        super(props);
        this.onDelete = this.onDelete.bind(this);
        this.onDownload = this.onDownload.bind(this);
        this.toggleLightBox = this.toggleLightBox.bind(this);
        this.state = { downloading: false, isImage: false, openLightBox: false, image: {large: '', small: ''} };
    }

    async componentWillMount() {
        if (this.props.contentType !== undefined && this.props.contentType.startsWith("image")) {
            this.setState({ isImage: true, image: {
                small : authenticationService.getUri('/tickets/' + this.props.ticketId + '/image/' + this.props.id + '?size=0'),
                large : authenticationService.getUri('/tickets/' + this.props.ticketId + '/image/' + this.props.id + '?size=1')
            } });
        }
    }

    onDelete(e: any) {
        e.stopPropagation();
        e.preventDefault();
        if (!this.state.downloading && this.props.onDelete) {
            this.props.onDelete(this.props.id);
        }
    }

    toggleLightBox() {
        if(this.state.image) {
            const current = this.state.openLightBox;
            this.setState({openLightBox : !current});
        }
    }


    async onDownload(e: any) {
        e.preventDefault();
        this.setState({ downloading: true });
        const fileContent = await ticketService.getAttachment(this.props.ticketId, this.props.id);
        var url = window.URL.createObjectURL(fileContent);
        var a = document.createElement('a');
        a.href = url;
        a.download = this.props.fileName;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
        this.setState({ downloading: false });
    }

    render() {
        const size = Attachment.formatSize(this.props.size);
        return (
            <li className="k-file">
                {
                    this.state.openLightBox && 
                    (<Lightbox 
                        large={this.state.image.large} 
                        alt={this.props.fileName} 
                        hideDownload={true}
                        hideZoom={true}
                        onClose={this.toggleLightBox} />)
                }
                <div className="k-file-single" onClick={this.toggleLightBox}>
                    {this.state.isImage && <div className={'k-image-preview'}>
                    <img src={this.state.image.small} alt={'Preview'}/> 
                    </div>}
                <span className="k-file-name-size-wrapper" onClick={this.toggleLightBox} title={this.state.isImage ? this.props.fileName : undefined}>
                    <span className="k-file-name" title={this.props.fileName}>{this.props.fileName}</span>
                    <span className="k-file-size">{size}</span>
                </span>
                <strong className="k-upload-status">
                    {this.props.canDelete && <div className="row">
                        <div className="col-12">
                            <button type="button" className="k-button k-upload-action" onClick={this.onDelete}><span aria-label="Remove" title="Remove" className="fa fa-lg fa-trash"></span></button>
                        </div>
        </div> }
                    <div className="row">
                        <div className="col-12">
                            <button type="button" className="k-button k-upload-action" onClick={this.onDownload}><span aria-label="Download" title="Download"><FontAwesomeIcon icon={this.state.downloading ? 'spinner' : 'download'} size="lg" animation={this.state.downloading ? 'spin' : undefined} /> </span></button>
                        </div>
                    </div>
                </strong>
                </div>
            </li >
        );
    }
}