import React, { Component, useEffect } from 'react';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import franchiseService, { FranchiseService } from './services/FranchiseService';
import { withTranslation, WithTranslation } from 'react-i18next';

type Props = {
    userId: number | null,
    required : boolean,
    disabled : boolean,
    onChange?: Function
}

type State = {
    value: any,
    users: Array<any>,
    country:number
}

export class FranchiseList extends Component<Props & WithTranslation, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            value: {},
            users: [],
            country:0
        };
        this.filterChange = this.filterChange.bind(this);
    }

    async componentWillMount() {
        let countryCode : number = 0;
        let list = await this.getUsers(null, countryCode);
        let franchise: any = null;
        if(this.props.userId) {
            franchise = list.find((u: any) => u.id === this.props.userId);
            if(franchise === null || franchise === undefined) {
                countryCode = -1;
                let disabledUsers = await this.getUsers(null, countryCode);
                franchise = disabledUsers.find((u: any) => u.id === this.props.userId);
                if(franchise === null || franchise === undefined) {
                    countryCode = 0;
                }
                else{
                    list = disabledUsers;
                }
            }
        }
        this.setState({ users: list, value: franchise, country: countryCode });
    }

    componentDidUpdate(prevProps: Props) {
        if(prevProps.userId !== this.props.userId)
        {
            let agent = null;
            if(this.props.userId)
            {
                const list = this.state.users;
                agent = list.find((u: any) => u.id === this.props.userId);
            }            
            this.setState({value: agent});
        }
        else if (prevProps.disabled !== this.props.disabled || prevProps.required !== this.props.required) {
            this.setState({});
        }
    }

    handleChange(value: any) {
        this.setState({ value: value });
        if (this.props.onChange) {
            this.props.onChange(value);
        }
    }

    async filterCountry(id : number)
    {
        let list = await this.getUsers(null, id);
        this.setState({users: list, country:id});          
    }

    async getUsers(searchValue : string | null, country: number)
    {
        let list = await franchiseService.getFranchises();
        if(country === -1) {
            list = list.filter((u : any) => ! u.enabled);
        }
        else
        {
            list = list.filter((u : any) => u.enabled);
            if(country !== 0)
            {
                list = list.filter((u : any) => u.countryCode === country);
            }
        }
        if(searchValue && searchValue.length > 0) {
            list = list.filter((u : any) => (u.firstName && u.firstName.toLowerCase().includes(searchValue)) || (u.lastName && u.lastName.toLowerCase().includes(searchValue)));
        }
        return list.sort(FranchiseService.compare).map((u : any) => {return { id: u.id, country: u.enabled ? u.countryCode : 'disabled', displayName: FranchiseService.formatUserName(u), enabled: u.enabled};});
    }

    async filterChange(event : any) {
        let filtered = await this.getUsers(event.filter.value, this.state.country);
        this.setState({users: filtered});  
    }

    itemRender = (li : any, itemProps : any) => {
        const itemChildren = <span><img width="16" style={{"padding":"2"}} src={"img/country-"+itemProps.dataItem.country+".png"} /> {li.props.children}</span>;
        return React.cloneElement(li, li.props, itemChildren);
    }

    render() {
        const { t } = this.props;
        return (
            <>
                <div className="flags">
                    <a title={t('Country.all')} className={this.state.country == 0 ? "active" : ""} onClick={(e:any) => this.filterCountry(0)}><img src="img/country-0.png" /></a>
                    <a title={t('Country.fr')} className={this.state.country == 1 ? "active" : ""} onClick={(e:any) => this.filterCountry(1)}><img src="img/country-1.png" /></a>
                    <a title={t('Country.be')} className={this.state.country == 2 ? "active" : ""} onClick={(e:any) => this.filterCountry(2)}><img src="img/country-2.png" /></a>
                    <a title={t('Country.mu')} className={this.state.country == 3 ? "active" : ""} onClick={(e:any) => this.filterCountry(3)}><img src="img/country-3.png" /></a>
                    <a title={t('Country.uk')} className={this.state.country == 4 ? "active" : ""} onClick={(e:any) => this.filterCountry(4)}><img src="img/country-4.png" /></a>
                    <a title={t('Country.disabled')} className={this.state.country == -1 ? "active" : ""} onClick={(e:any) => this.filterCountry(-1)}><img src="img/country-disabled.png" /></a>
                </div>
                <ComboBox className={'form-control'} data={this.state.users} textField={'displayName'} dataItemKey={'id'}
                value={this.state.value}
                filterable={true}
                itemRender={this.itemRender}
                required={this.props.required}
                disabled={this.props.disabled}
                onFilterChange={this.filterChange}
                onChange={(e: any) => {
                    this.handleChange(e.target.value);
                }} />
            </>);
    }
}

export default withTranslation()(FranchiseList);
