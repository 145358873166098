import React, { Component } from 'react';
import Topbar from '../../Topbar';
import NewTicketInfobar from '../NewTicketInfobar';
import { Card, Row, Col, Form } from 'react-bootstrap';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Upload, UploadFileStatus } from '@progress/kendo-react-upload';
import AgentList from '../../AgentList';
import FranchiseList from '../../FranchiseList';
import ThemeList from '../ThemeList';

import { TicketStatusDefinitions, TicketPriorityDefinitions, internationalize } from '../../Enums'
import themeService from '../../services/ThemeService';
import ticketService, { TicketService } from '../../services/TicketService';
import { Redirect } from 'react-router';
import authenticationService from '../../services/AuthenticationService';
import { ServiceUri } from '../../services/ServicesUri';
import { withTranslation } from 'react-i18next';
import validationService from '../../services/TicketValidationService';
import agentService from '../../services/AgentService';

type AgentNewTicketFormState = {
    id: number,
    isValid: boolean,
    subject: string,
    description: string,
    theme: any,
    status: any,
    priority: any,
    requestedBy: number | null,
    processedBy: number | null,
    files: Array<any>,
    defaultFiles: Array<any>,
    saved: boolean,
    errorCode: string | null,
    errors : {
        subject: string | null,
        description : string | null,
        requestedBy : string | null
    }
}

class AgentNewTicketForm extends Component<any, AgentNewTicketFormState> {
    constructor(props: any) {
        super(props);
        const { t } = this.props;
        internationalize(TicketPriorityDefinitions, 'Priority.label', t);
        internationalize(TicketPriorityDefinitions, 'Priority.description', t, 'description');
        internationalize(TicketStatusDefinitions, 'Status', t);

        this.state = {
            id: 0,
            isValid: false,
            saved: false,
            subject: '',
            description: '',
            theme: themeService.first(),
            status: TicketStatusDefinitions[0],
            priority: TicketPriorityDefinitions[2],
            requestedBy: null,
            files: [],
            defaultFiles: [],
            processedBy: null,
            errorCode: null,
            errors : {
                subject : t('Error.mandatoryField'),
                description : t('Error.mandatoryField'),
                requestedBy : t('Error.mandatoryField')
            }
        }
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onAddFiles = this.onAddFiles.bind(this);
        this.onRemoveFiles = this.onRemoveFiles.bind(this);
        this.onStatusChange = this.onStatusChange.bind(this);
        this.affectMe = this.affectMe.bind(this);
        this.changeAgent = this.changeAgent.bind(this);
    }

    onCancel() {
        var files = this.state.files;
        if(files.some((file) => file.status === UploadFileStatus.Uploading))
        {
            return;
        }
        this.setState({ saved: true });
    }

    async onSave() {
        if (! this.state.isValid || (this.state.id !== 0)) {
            return;
        }

        const ticket = {
            subject: this.state.subject,
            description: this.state.description,
            theme: this.state.theme.id,
            status: this.state.status.id,
            priority: this.state.priority.id,
            requestedBy: this.state.requestedBy,
            processedBy: this.state.processedBy
        };
        try {
            const summary = await ticketService.addTicket(ticket);
            const files = this.state.files;
            if(files.length > 0)
            {
                files.forEach((file : any, index: number, array: any[]) => {
                    file.status = UploadFileStatus.Uploading;
                    ticketService.addAttachment(summary.id, file.getRawFile()).then(() => 
                    {
                        file.status = UploadFileStatus.Uploaded;
                        this.setState({ files : array, saved : array.every(value => value.status === UploadFileStatus.Uploaded) });
                    });
                });
            }
            this.setState({ saved: (this.state.files.length === 0), id: summary.id, errorCode:null });
        }
        catch
        {
            this.setState({ saved: false, errorCode: 'internalError' });
        }
    }

    onBeforeUpload(e: any) {
        e.headers = authenticationService.prepareRequest({});
    }

    onAddFiles(e: any) {
        this.setState({ files: e.newState });
    }

    onRemoveFiles(e: any) {
        this.setState({ files: e.newState });
    }

    onStatusChange(e: any) {
        let item = e.newState.find((f: any) => f.status === UploadFileStatus.Selected || f.status === UploadFileStatus.Uploading);
        if (!item) {
            this.setState({ saved: true });
        }
    }

    affectMe(e: any) {
        e.preventDefault();
        this.changeAgent(authenticationService.me());
    }

    changeAgent(user: any) {
        console.error(user);
        let status = this.state.status;
        if (user && (user.id !== this.state.processedBy)) {
            status = TicketStatusDefinitions.find((item) => item.id === 7);
        }
        else if(user === null || user === undefined)
        {
            status = TicketStatusDefinitions.find((item) => item.id === 1);
        }
        this.setState({ processedBy: user?.id, status: status });
    }

    validate() {
        const { t } = this.props;

        const subjectError = validationService.format(t, 'Error', validationService.isValidSubject(this.state.subject));
        const requestedByError = validationService.format(t, 'Error',validationService.isValidRequester(this.state.requestedBy));
        const descriptionError = validationService.format(t, 'Error',validationService.isValidDescription(this.state.description));

        this.setState({
            isValid: (subjectError === null) && (descriptionError === null) && (requestedByError === null),
            errors : {
                        subject : subjectError, 
                        description : descriptionError,
                        requestedBy : requestedByError
                    }
        });
    }
    setRequester(user: any) {
        this.setState( {requestedBy:user?.id}, this.validate);
    }
    setSubject(value: string) {
        this.setState( {subject:value}, this.validate);
    }

    setDescription(value: string) {
        this.setState( {description:value}, this.validate);
    }

    render() {
        if (this.state.saved) {
            return <Redirect to={'/tickets'} />
        }
        else {
            const { t } = this.props;
            return (
                <div>
                    <Topbar />
                    <NewTicketInfobar canSave={this.state.isValid && (this.state.id===0)} onSave={this.onSave} onCancel={this.onCancel}  />
                    <div className="main-wrapper">
                        <Form noValidate validated={false}>
                        <div className="container">
                            {this.state.errorCode && (this.state.errorCode !== '') && (<div className={'alert alert-warning'}>{t('NewTicketError.' + this.state.errorCode)}
                            </div>)}
                            <Row>
                                <Col md={6}>
                                    <Card>
                                        <Card.Header>{t('AgentNewTicketForm.requestAttributes')}</Card.Header>
                                        <Card.Body>
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label>{t('AgentNewTicketForm.requestedBy')}</label>
                                                    <FranchiseList 
                                                        userId={this.state.requestedBy} 
                                                        required={true}
                                                        disabled={false}
                                                        onChange={(user: any) => { this.setRequester(user); }} />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label>{t('AgentNewTicketForm.processedBy')}</label><a className={'float-right'} href="/me"
                                                        onClick={this.affectMe}>{t('AgentNewTicketForm.me')}</a>
                                                    <AgentList 
                                                        userId={this.state.processedBy} 
                                                        required={false}
                                                        disabled={false}
                                                        onChange={this.changeAgent} />
                                                </div>
                                            </div>
                                            {this.state.processedBy !== null &&
                                            (<div className="form-row">
                                                <div className="col-md-6"></div>
                                                <div className="form-group col-md-6">
                                                    <label>{t('AgentEditTicketForm.status')}</label>
                                                    <DropDownList className={'form-control'} data={agentService.getNewFormStates()} textField={'label'} dataItemKey={'id'}
                                                        value={this.state.status}
                                                        onChange={(e: any) => {
                                                            this.setState({ status: e.target.value });
                                                        }} />
                                                </div>
                                            </div>)}
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label>{t('AgentNewTicketForm.theme')}</label>
                                                    <ThemeList value={this.state.theme} onChange={(value: any) => {
                                                        this.setState({ theme: value });
                                                    }} />

                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label>{t('AgentNewTicketForm.priority')}</label>
                                                    <DropDownList className={'form-control'} data={TicketPriorityDefinitions} textField={'label'} dataItemKey={'id'}
                                                        value={this.state.priority}
                                                        onChange={(e: any) => {
                                                            this.setState({ priority: e.target.value });
                                                        }} />
                                                    <span  className="priority-description">{this.state.priority.description}</span>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                    <Card>
                                        <Card.Body>
                                            <div className="form-group required">
                                                <label htmlFor="subject">{t('AgentNewTicketForm.subject')}</label>
                                                <input type="text" id="subject" className={'form-control' + (this.state.errors.subject === null ? '' : ' is-invalid')}
                                                    maxLength={250}
                                                    minLength={20}
                                                    value={this.state.subject}
                                                    onChange={(e: any) => { this.setSubject(e.target.value); }} />
                                                <div className="invalid-feedback">{this.state.errors.subject}</div>
                                            </div>
                                            <div className="form-group required">
                                                <label htmlFor="description">{t('AgentNewTicketForm.description')}</label>
                                                <textarea id="description" rows={10} className={'form-control' + (this.state.errors.description === null ? '' : ' is-invalid')}
                                                    value={this.state.description}
                                                    onChange={(e: any) => { this.setDescription(e.target.value); }} />
                                                <div className="invalid-feedback">{this.state.errors.description}</div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={6}>
                                    <Card>
                                        <Card.Body>
                                            <div className="form-group">
                                                <label>{t('AgentNewTicketForm.attachments')}</label>
                                                <Upload multiple={true} batch={false} autoUpload={false}
                                                    onBeforeUpload={this.onBeforeUpload} withCredentials={false}
                                                    showActionButtons={(!this.state.saved) && (this.state.id > 0)}
                                                    onAdd={this.onAddFiles}
                                                    onRemove={this.onRemoveFiles}
                                                    onStatusChange={this.onStatusChange}
                                                    files={this.state.files}
                                                    saveUrl={ServiceUri + '/tickets/' + this.state.id + '/attachment'}
                                                    restrictions={{
                                                        allowedExtensions: TicketService.AllowedAttachmentExtensions
                                                    }} />
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                        </Form>
                    </div>
                </div>
            );
        }
    }
}

export default withTranslation()(AgentNewTicketForm);