import React, { Component } from 'react';
import Moment from 'react-moment';
import { MetaFieldProps } from './MetaDataItems';
import { withTranslation, WithTranslation } from 'react-i18next';

class MetaDateTimeField extends Component<MetaFieldProps & WithTranslation, any> {
    render() {
        const {t} = this.props;
        let content = this.props.value ?
            (<Moment className="value" element="span" format={t('moment.dateTimeFormat')} date={this.props.value} local={true} withTitle />)
            : (<span className="value">--</span>);
        return (
            <div className="meta">
                <span className="label">{this.props.label}</span>
                {content}
            </div>);
    };
}

export default withTranslation()(MetaDateTimeField);

