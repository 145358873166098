import jsonService from './jsonService';
import authenticationService from './AuthenticationService';
import { TicketStatusDefinitions } from '../Enums'

type User = {
    id: number,
    lastName: string,
    firstName: string
}

export class AgentService {
    private _agents = null;

    reset() {
        this._agents = null;
    }

    static compare(aUser: User, bUser: User) {
        let bandA = aUser.lastName.toUpperCase();
        let bandB = bUser.lastName.toUpperCase();

        let comparison = 0;
        if (bandA > bandB) {
            comparison = 1;
        } else if (bandA < bandB) {
            comparison = -1;
        }
        else {
            bandA = aUser.firstName.toUpperCase();
            bandB = bUser.firstName.toUpperCase();
            if (bandA > bandB) {
                comparison = 1;
            } else if (bandA < bandB) {
                comparison = -1;
            }
        }
        return comparison;
    }

    getNewFormStates() {
        return TicketStatusDefinitions.filter((s:any) => s.id !== 1 && s.id !== 8);
    }

    getStates(current : number) {
        return TicketStatusDefinitions.filter((s:any) => s.id !== 8);
        // if(current === 1) { //Nouveau
        //     return TicketStatusDefinitions.filter((s:any) => s.id === 1 || s.id === 7 || s.id === 2);
        // }
        // else if(current === 2) { //Accepté
        //     return TicketStatusDefinitions.filter((s:any) => s.id !== 1 && s.id !== 8);
        // }
        // else if(current === 3) { //En attente de précisions par le franchisé
        //     return TicketStatusDefinitions.filter((s:any) => s.id === 2 || s.id === 3 || s.id === 4 ||  s.id === 5 || s.id === 6);
        // }
        // else if(current === 4) { //En attente d'éléments externes
        //     return TicketStatusDefinitions.filter((s:any) => s.id === 2 || s.id === 3 || s.id === 4 ||  s.id === 5 || s.id === 6);
        // }
        // else if(current === 5) { //Terminé
        //     return TicketStatusDefinitions.filter((s:any) => s.id === 2 || s.id === 3 || s.id === 4 ||  s.id === 5 || s.id === 6|| s.id === 8);
        // }
        // else if(current === 6) { //Refusé
        //     return TicketStatusDefinitions.filter((s:any) => s.id === 2 || s.id === 3 || s.id === 4 ||  s.id === 5 || s.id === 6 || s.id === 8);
        // }
        // else if(current === 7) { //Affecté
        //     return TicketStatusDefinitions.filter((s:any) => s.id === 2 || s.id === 6 || s.id === 7 );
        // }
        // else if(current === 8) { //Fermé
        //     return TicketStatusDefinitions.filter((s:any) => s.id === 8);
        // }
        // else{
        //     return TicketStatusDefinitions;
        // }
    }

    static formatUserName(user: any) {
        if (user) {
            return user.lastName + ", " + user.firstName;
        }
        else {
            return '';
        }
    }

    async getAgents() {
        if (this._agents) {
            return this._agents;
        }
        if (authenticationService.isAuthenticated()) {
            const response = await jsonService.get('/users/agents', authenticationService.prepareRequest({}));
            const data = await response.json();
            this._agents = data;
            return data;
        }
        else {
            this._agents = null;
            return [];
        }
    }


    findAgent(id: number | null) {
        if (id && this._agents) {
            const data = this._agents || [];
            return data.find((u: User) => u.id === id);
        }
        return undefined;
    }
}

const agentService = new AgentService();
export default agentService;