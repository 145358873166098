import { ServiceUri } from './ServicesUri';
import * as jwt from 'jwt-decode';
import agentService from './AgentService';
import franchiseService from './FranchiseService';
import themeService from './ThemeService';
import jsonService from './jsonService';

const Claims = {
    email: 'mail',
    role: 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role',
    firstName: 'firstName',
    lastName: 'lastName',
    id: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'
}

export class AuthenticationService {
    private _token: string | null = null;
    static readonly localStorageTokenKey = "token";
    static readonly AuthorizationHeader = "Authorization";
    constructor() {
        this.setToken(window.localStorage.getItem(AuthenticationService.localStorageTokenKey));
    }


    me() {
        if (this._token) {
            try {
                const decodedToken: any = jwt.default(this._token);
                const m = {
                    mail: decodedToken[Claims.email],
                    displayName: decodedToken[Claims.firstName] + ' ' + decodedToken[Claims.lastName],
                    role: decodedToken[Claims.role],
                    id: parseInt(decodedToken[Claims.id]),
                    expirationTime: decodedToken.exp
                };
                if (Date.now() < m.expirationTime * 1000) {
                    return m;
                }
            }
            catch(e) {
                this.setToken(null);
            }
        }
        return {
            role: '',
            mail: '',
            displayName: '',
            id: 0,
            expirationTime: 0
        };
    }

    isAuthenticated() {
        const m = this.me();
        return (m !== null) && (m !== undefined) && (Date.now() < m.expirationTime * 1000);
    }

    async login(username: string, password: string, culture?: string) {
        var formData = encodeURIComponent('username') + '=' + encodeURIComponent(username) + '&' + encodeURIComponent('password') + '=' + encodeURIComponent(password);
        if (culture) {
            formData = formData + '&' + encodeURIComponent('culture') + '=' + encodeURIComponent(culture)
        }
        const url = ServiceUri + '/login/token';
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            },
            body: formData
        });
        
        if (response.status === 200) {
            const data = await response.json();
            this.setToken(data);
        }
        else {
            this.setToken(null);
        }
        return this.me();
    }

    logout() {
        this.setToken(null);
        agentService.reset();
        franchiseService.reset();
        themeService.reset();
    }

    getUri(uri: string) {
        return jsonService._baseUri + uri + "&token=" + this._token;
    }

    prepareRequest(header: any | null) {
        if(header === null)
        {
            header = {};
        }
        if (this._token) {
            header[AuthenticationService.AuthorizationHeader] = 'Bearer ' + this._token;
        }
        return header;
    }

    auth(token?: string) {
        if (token) {
            this.setToken(token);
        }
    }

    private setToken(value: string | null) {
        this._token = value;
        if (this._token && this._token !== '') {
            window.localStorage.setItem(AuthenticationService.localStorageTokenKey, this._token);
        }
        else {
            window.localStorage.removeItem(AuthenticationService.localStorageTokenKey);
        }
    }
}

const authenticationService = new AuthenticationService();

export default authenticationService;