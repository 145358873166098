import React from 'react';
import { Route, Switch, Redirect } from 'react-router';
import LoginForm from './components/LoginForm';
import TicketList from './components/tickets/TicketList';
import TicketForm from './components/ticket-form/TicketForm';
import PkiPage from './components/PkiPage';

const App: React.FC = () => {
  return (
    <Switch>
      <Route exact path={'/login'} component={LoginForm} />
      <Route path={'/tickets'} component={TicketList} />
      <Route path={'/ticket/:ticketId?'} component={TicketForm} />
      <Route path={'/kpi'} component={PkiPage} />
      <Route path={'/*'} >
        <Redirect to={'/login'} />
      </Route>
    </Switch>
  );
}

export default App;
