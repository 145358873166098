import React, { Component } from 'react';
import { TicketPriorityDefinitions } from '../Enums';
type TicketPriorityProps = {
    priority: number
}

export default class TicketPriority extends Component<TicketPriorityProps, any> {
    
    render() {
        const priorityDefinition = TicketPriorityDefinitions.find(item => item.id === this.props.priority);
        if (priorityDefinition) {
            return (<span className={'icon-priority ' + priorityDefinition.class} title={priorityDefinition.label}><i className={priorityDefinition.icon}></i></span>);
        }
        else {
            return (<span>#{this.props.priority}#</span>);
        }
    }
}