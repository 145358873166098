import React, { Component, Fragment } from 'react';
import BarChart from './BarChart';
import PieChart from './PieChart';
import pkiService from './services/PkiService';
import ThemeList from './ticket-form/ThemeList';
import { Redirect } from 'react-router-dom';
import FontAwesomeIcon from './FontAwesome';

import Topbar from './Topbar';
import { Row, Col } from 'react-bootstrap';
import { StatCard } from './tickets/StatCard';
import authenticationService from './services/AuthenticationService';
import themeService from './services/ThemeService';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
type RequestPerMonthState = {
    data: any[],
    criticalDelay: any[],
    datasets: any[],
    theme: any | undefined,
    themeLabels: string[],
    themeStats: any[]
}

export class RequestPerMonth extends Component<any & WithTranslation, RequestPerMonthState> {
    static months = ['jan.', 'feb.', 'mar.', 'apr.', 'may', 'june', 'jul.', 'aug.', 'sept.', 'oct.', 'nov.', 'dec.'];
    constructor(props: any) {
        super(props);
        this.state = {
            data: [],
            datasets: [],
            criticalDelay: [],
            theme: undefined,
            themeLabels: [],
            themeStats: []
        };
        this.onChangeTheme = this.onChangeTheme.bind(this);
    }

    getDataSets(data: any[]) {
        const {t} = this.props;
        return data.map((item: any, index: number) => {
            return {
                title: t('pkiForm.requestPerMonthForYear', {year: item.year}),
                values: item.perMonths.map((month: any) => {
                    return month.perThemes.reduce((accumulator: number, themeCounter: any) => { return (accumulator + themeCounter.count); }, 0);
                }),
                color: index === 0 ? 'rgba(59, 153, 240, 0.75)' : 'rgba(245, 54, 92, 0.75)'
            };
        });
    }

    getThemeStats(data: any[]): any[] {
        const {t} = this.props;
        const themes = themeService.Themes();
        return data.map((item: any, index: number) => {
            return {
                title: t('pkiForm.year', {year: item.year}),
                values: themes?.map((theme: any): number => {
                    return item.perMonths.reduce((acc: number, month: any): number => {
                        const themeCounter = month.perThemes.find((themeCounter: any) => themeCounter.theme === theme.id);
                        return acc + (themeCounter?.count || 0);
                    }, 0);
                }) || [],
                colors: ['gray', 'red', 'blue']
            }
        });
    }

    getDataSetTheme(data: any[], theme: number) {
        const {t} = this.props;
        return data.map((item: any, index: number) => {
            return {
                title: t('pkiForm.requestPerMonthForYear', {year: item.year}),
                values: item.perMonths.map((month: any) => {
                    const themeCounter = month.perThemes.find((themeCounter: any) => themeCounter.theme === theme);
                    return themeCounter?.count || 0;
                }),
                color: index === 0 ? 'rgba(59, 153, 240, 0.75)' : 'rgba(245, 54, 92, 0.75)'
            };
        });
    }


    async onChangeTheme(theme: any) {
        if (theme.id === 0) {
            const avgDelays = await pkiService.getAverageDurations();
            this.setState({ datasets: this.getDataSets(this.state.data), criticalDelay: avgDelays, theme: theme, themeStats: this.getThemeStats(this.state.data) });
        }
        else {
            const avgDelays = await pkiService.getAverageDurations(theme.id);
            this.setState({ datasets: this.getDataSetTheme(this.state.data, theme.id), criticalDelay: avgDelays, theme: theme });
        }
    }


    getAverage(dataset: any, year: number) {
        let count = dataset.values.length;
        const sum = this.getTotal(dataset);
        if (year === new Date().getFullYear()) {
            count = new Date().getMonth() + 1;
        }
        return Math.floor(sum / count);
    }

    getTotal(dataset: any) {
        return dataset.values.reduce((acc: number, current: number) => acc + current, 0);
    }

    async componentWillMount() {
        const data: any[] | undefined = await pkiService.getRequestPerMonth();
        const themes = await themeService.getThemes();

        if (data !== undefined) {
            const dataSets = this.getDataSets(data);
            const durations = await pkiService.getAverageDurations();
            this.setState({ data: data, datasets: dataSets, criticalDelay: durations, themeLabels: themes.map((theme: any) => theme.label), themeStats: this.getThemeStats(data) });
        }
        else {
            this.setState({ themeLabels: themes.map((theme: any) => theme.label) });
        }

    }

    render() {
        const {t} = this.props;
        const me = authenticationService.me();
        if (!authenticationService.isAuthenticated()) {
            return (<Redirect to={'/login'} />);
        }
        else if (me.role === 'franchise') {
            return (<Redirect to={'/tickets'} />);
        }
        else {
            return (
                    <div className={'main-wrapper'}>
                        <div className={'container'}>
                            <ThemeList allowNull onChange={this.onChangeTheme} value={this.state.theme} />
                            <Row>
                                <Col md={(this.state.theme && this.state.theme.id > 0) ? 12 : 9}>
                                    <Row>
                                        {
                                            this.state.datasets.length > 0 &&
                                            <Col md={6} className={'mb-4'}>
                                                <StatCard variant={'blue'}>
                                                    <Row>
                                                        <Col md={6} style={{ textAlign: 'center' }}>
                                                            <div className="stat-value">
                                                                <Trans i18nKey={'pkiForm.ratio'} values={{ value: this.getAverage(this.state.datasets[0], this.state.data[0].year), total :  this.getTotal(this.state.datasets[0])}} />
                                                            </div>
                                                            <div className="stat-label">{t('pkiForm.ticketsPerMonth')}</div>
                                                        </Col>
                                                        <Col md={6} style={{ textAlign: 'center' }}>
                                                            <div className="stat-value">
                                                                <Trans i18nKey={'pkiForm.avgDuration'} values={{ averageDuration: this.state.criticalDelay[0].averageDuration}} />
                                                            </div>
                                                            <div className="stat-label">{t('pkiForm.criticaltickets')}</div>
                                                        </Col>
                                                    </Row>
                                                    <div className={'stat-header'}>{this.state.data[0].year}</div>
                                                </StatCard>
                                            </Col>
                                        }
                                        {
                                            this.state.datasets.length > 1 &&
                                            <Col md={6} className={'mb-4'}>
                                                <StatCard variant={'red'}>
                                                    <Row>
                                                        <Col md={6} style={{ textAlign: 'center' }}>
                                                            <div className="stat-value">
                                                                <Trans i18nKey={'pkiForm.ratio'} values={{ value: this.getAverage(this.state.datasets[1], this.state.data[1].year), total :  this.getTotal(this.state.datasets[1])}} />
                                                            </div>
                                                            <div className="stat-label">{t('pkiForm.ticketsPerMonth')}</div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className="stat-value" style={{ textAlign: 'center' }}>
                                                                <Trans i18nKey={'pkiForm.avgDuration'} values={{ averageDuration: this.state.criticalDelay[1].averageDuration}} />
                                                            </div>
                                                            <div className="stat-label">{t('pkiForm.criticaltickets')}</div>
                                                        </Col>
                                                    </Row>
                                                    <div className={'stat-header'}>{this.state.data[1].year}</div>
                                                </StatCard>
                                            </Col>
                                        }
                                    </Row>

                                    <BarChart variant={'bar'}
                                        labels={RequestPerMonth.months}
                                        data={this.state.datasets}
                                        className={'mb-4'} />

                                </Col>

                                {(!this.state.theme || this.state.theme.id === 0) && <Col md={3}>
                                    <PieChart variant={'doughnut'}
                                        labels={this.state.themeLabels}
                                        data={this.state.themeStats[0]}
                                        className={'mb-4'} />
                                    <PieChart variant={'doughnut'}
                                        labels={this.state.themeLabels}
                                        data={this.state.themeStats[1]}
                                        className={'mb-4'} />
                                </Col>
                                }

                            </Row>
                        </div>
                    </div>
                );
        }
    }
}

export default withTranslation()(RequestPerMonth);